import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';

// Worker saga
function* stergereCredentiale(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'stergere_credentiale');
    if (response.error) {
      yield put({ type: 'STERGERE_CREDENTIALE_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'STERGERE_CREDENTIALE_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });

      // la stergerea credentialelor se preiua din nou datele contului din baza de date
      yield put({ type: 'DATE_CONT_REQUEST', payload: { token: action.payload.token } });
    }
  } catch (error) {
    yield put({ type: 'STERGERE_CREDENTIALE_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchStergereCredentiale() {
  yield takeLatest('STERGERE_CREDENTIALE_REQUEST', stergereCredentiale);
}

export default watchStergereCredentiale;
