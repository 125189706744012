import Modal from '@mui/material/Modal';
import { TitluFormular } from './caseteText';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { useEffect } from 'react';
import { theme } from '../constants/themeColors1';
import { CloseRounded, DoneRounded } from '@mui/icons-material';

const styles = StyleSheet.create({
  container: {
    width: '50%',
    maxWidth: 800,
    height: '50',
    backgroundColor: theme.bg300,
    borderRadius: 10,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  buton: {
    backgroundColor: theme.primary100,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 7,
    height: 35,
    margin: 5,
    justifyContent: 'center',
  },

  containerButoane: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'right',
    marginBottom: 10,
  },

  icon: {
    color: theme.primary300,
    width: 25,
    height: 25,
    marginLeft: 1,
    alignSelf: 'center',
  },
});

export default function DialogAlerta({ vizibil, setVizibil, titlu, text, buton }) {
  const handleOpen = () => {
    setVizibil(true);
  };
  const handleClose = () => {
    setVizibil(false);
  };
  const handleDa = () => {
    setVizibil(false);
    if (buton) buton();
  };
  useEffect(() => {
    if (vizibil) handleOpen();
  }, [vizibil]);

  const Buton = ({ onPress, label, icon }) => {
    return (
      <TouchableOpacity style={styles.buton} onPress={onPress}>
        {icon}
        <Text style={{ color: theme.text100, margin: 10, fontSize: 16, fontWeight: '800' }}>{label}</Text>
      </TouchableOpacity>
    );
  };

  return (
    <div>
      <Modal open={vizibil} onClose={handleClose}>
        <View style={styles.container}>
          <TitluFormular label={titlu} />
          <View style={{ width: '85%', alignSelf: 'center' }}>
            <Text style={{ color: theme.text100, margin: 20, fontSize: 18 }}>{text}</Text>

            <View style={styles.containerButoane}>
              <Buton label={'NU'} icon={<CloseRounded sx={styles.icon} />} onPress={handleClose} />
              <Buton label={'DA'} icon={<DoneRounded sx={styles.icon} />} onPress={handleDa} />
            </View>
          </View>
        </View>
      </Modal>
    </div>
  );
}
