import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useWindowDimensions, Text, Image, View } from 'react-native';
import { useNotification } from '../context/NotificationProvider';
import { useDispatch, useSelector } from 'react-redux';
import SignInPage from '../pages/SignInPage';
import DashboardPage from '../pages/DashboardPage';
import SetariUtilizatorPage from '../pages/SetariUtilizatorPage';
import CreareContPage from '../pages/CreareContPage';
import { NavigationProvider } from '../context/NavigationContext';
import { theme, themeStyles } from '../constants/themeColors1';
import { DashboardRounded, SettingsRounded, StorageRounded } from '@mui/icons-material';

const NavigationBar = () => {
  const { height } = useWindowDimensions();
  const [token, setToken] = useState(null);
  const { enqueueSnackbar } = useNotification();
  const stateNotificare = useSelector((state) => state.notificare);
  const auth = useSelector((state) => state.auth.user);
  const dateCont = useSelector((state) => state.dateCont.dateContUtilizator);
  const dispatch = useDispatch();

  // se verifica daca existe date in localStorage si se adauga acestea in store-ul de la redux-saga
  useEffect(() => {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      let response = JSON.parse(storedData);
      dispatch({ type: 'LOGIN_SUCCESS', payload: response });
    }
  }, []);

  // se verifica daca auth este diferit de null si se preia tokenul
  useEffect(() => {
    if (auth) {
      setToken(auth.token);
      // se preiua datele cu caracter administrativ despre cont
      dispatch({ type: 'DATE_CONT_REQUEST', payload: { token: auth.token } });
    }
  }, [auth]);

  // afiseaza snackbar-urile cu mesaje primite din functiile generatoare din sagas
  useEffect(() => {
    if (stateNotificare.mesajNotificare) {
      enqueueSnackbar(stateNotificare.mesajNotificare, { variant: stateNotificare.tipNotificare });
    }
  }, [stateNotificare]);

  // buton reutilizabil afisat in bara de navigare
  const ButonNavBar = ({ nume, path, icon }) => {
    return (
      <Button component={Link} to={path} sx={themeStyles.butonNavBar}>
        {icon}
        <Text style={themeStyles.textButonNavbar}>{nume}</Text>
      </Button>
    );
  };

  return (
    <Router>
      <NavigationProvider>
        {/*  // BARA DE NAVIGARE APARE DOAR ATUNCI CAND TOKEN-UL ESTE DIFERIT DE NULL */}
        {token && dateCont && (
          <AppBar
            position="static"
            sx={{
              height: height / 15,
              backgroundColor: theme.bg200,
              justifyContent: 'center',
            }}
          >
            <Toolbar sx={{ height: '100%' }}>
              <View style={{ flexDirection: 'row', width: '30%', alignItems: 'center' }}>
                <Image source={require('../assets/logo_home.png')} style={{ width: 35, height: 35, marginRight: 15 }} />
                <Text style={{ color: theme.text100, fontSize: 18, fontWeight: '700' }}>{dateCont.nume_utilizator}</Text>
              </View>

              <View style={{ flexDirection: 'row', width: '70%', justifyContent: 'flex-end' }}>
                <ButonNavBar nume={'Dashboard'} path={'/dashboard'} icon={<DashboardRounded sx={themeStyles.iconButonNavBar} />} />
                <ButonNavBar nume={'Setări'} path={'/setari'} icon={<SettingsRounded sx={themeStyles.iconButonNavBar} />} />
              </View>
            </Toolbar>
          </AppBar>
        )}
        <Routes>
          <Route path="/" element={<SignInPage />} />
          <Route path="/creare-cont" element={<CreareContPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/setari" element={<SetariUtilizatorPage />} />
        </Routes>
      </NavigationProvider>
    </Router>
  );
};

export default NavigationBar;
