import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Animated, TouchableOpacity } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fadeInAnimation, fadeOutAnimation } from '../constants/animations';
import { useNavigate } from 'react-router-dom';
import { ButonSubmit, CasetaTextEmail, CasetaTextParola } from '../components/caseteText';
import { IndicatorStare } from '../components/IndicatorStare';
import { maxWidth, theme, themeStyles } from '../constants/themeColors1';
import { isDesktop } from 'react-device-detect';

const SignInPage = () => {
  const { height, width } = useWindowDimensions();
  const fadeAnimLogo = useRef(new Animated.Value(0)).current;
  const fadeAnimText = useRef(new Animated.Value(0)).current;
  const fadeAnimFormular = useRef(new Animated.Value(0)).current;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // REGEX PENTRU VERIFICARE EMAIL
  const parolaRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // REGEX PENTRU VERIFICARE PAROLA
  const [email, setEmail] = useState('');
  const [parola, setParola] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [obiectErori, setObiectErori] = useState({
    eroareEmail: false,
    eroareParola: false,
  });
  const indicator = useSelector((state) => state.auth.loading);
  const auth = useSelector((state) => state.auth.user);

  // La deschiderea paginii se activează animația de fade in
  useEffect(() => {
    Animated.parallel([
      fadeInAnimation(fadeAnimLogo),
      fadeInAnimation(fadeAnimText, 2000, 300),
      fadeInAnimation(fadeAnimFormular, 2000, 400),
    ]).start();
  }, []);

  useEffect(() => {
    if (auth) {
      if (auth.token) {
        navigate('/dashboard');
      }
    }
  }, [auth]);
  const handleNavigareCreareCont = () => {
    // Se inițiază fade out pentru componentele de pe ecran
    Animated.parallel([fadeOutAnimation(fadeAnimLogo), fadeOutAnimation(fadeAnimText), fadeOutAnimation(fadeAnimFormular)]).start(() => {
      // După finalizarea animațiilor, navighează la pagina de creare cont
      navigate('/creare-cont');
    });
  };

  // functia care se ocupa de verificarea validitatii datelor si care se ocupa cu trimiterea datelor catre server
  const handleLogin = () => {
    let mesaj = '';
    const erori = {
      eroareEmail: !email || !emailRegex.test(email),
      eroareParola: !parola || !parolaRegex.test(parola),
    };

    // Generare mesaj de eroare
    if (erori.eroareEmail) mesaj += 'Adresa de email nu este validă! ';
    if (erori.eroareParola) mesaj += 'Parola trebuie să conțină minim 8 caractere, inclusiv cel puțin o literă mare, o cifră și un caracter special.';

    setObiectErori(erori);
    if (mesaj) {
      dispatch({ type: 'NOTIFICARE_EROARE', payload: mesaj });
    }

    // Resetează erorile după 2 secunde
    setTimeout(() => {
      setObiectErori({ eroareEmail: false, eroareParola: false });
    }, 2000);

    // dacă toate condițiile sunt îndeplinite se trimit datele către backend
    if (!erori.eroareEmail && !erori.eroareParola) {
      dispatch({ type: 'LOGIN_REQUEST', payload: { email: email.trim(), parola: parola.trim() } });
    }
  };

  return (
    <View style={[themeStyles.fundal, { height: height }]}>
      <View style={themeStyles.continerPaginaLogin}>
        {/* Sectiunea cu icon si text */}
        <View style={themeStyles.containerStanga}>
          <Animated.Image source={require('../constants/logo.png')} style={[themeStyles.iconLogin, { opacity: fadeAnimLogo }]} />
          {/* Titlu pagina */}
          <Animated.Text style={[themeStyles.titluLogin, { opacity: fadeAnimText }]}>Home Control - Autentificare</Animated.Text>
          {/* Buton navigare catre pagina de creare cont - APARE NUMAI DE DESKTOP */}
          {isDesktop && (
            <Animated.Text style={[themeStyles.titluLogin, { opacity: fadeAnimText, fontSize: 25, fontStyle: 'italic' }]}>
              Nu aveți cont?{' '}
              <TouchableOpacity onPress={() => handleNavigareCreareCont()}>
                <Text style={{ color: theme.accent100, textDecorationLine: 'underline' }}>Înregistrați-vă aici!</Text>
              </TouchableOpacity>
            </Animated.Text>
          )}
        </View>

        {/* FORMULAR AUTENTIFICARE */}
        <View style={themeStyles.containerStanga}>
          <Animated.View style={[themeStyles.fundalFormularLogin, { opacity: fadeAnimFormular }]}>
            {/* CASETA IMPUT EMAIL */}
            <CasetaTextEmail label={'Email'} value={email} setValue={setEmail} error={obiectErori.eroareEmail} fieldStyle={{ marginBottom: '3%' }} />
            {/* CASETA INPUT PAROLA */}
            <CasetaTextParola
              label={'Parola'}
              value={parola}
              setValue={setParola}
              error={obiectErori.eroareParola}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              fieldStyle={{ marginBottom: '15%' }}
            />
            {/* BUTON AUTENTIFICARE */}
            <ButonSubmit label={'Autentificare'} onPress={() => handleLogin()} />
          </Animated.View>
        </View>
      </View>

      <IndicatorStare vizibil={indicator} />
    </View>
  );
};

export default SignInPage;
