import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Animated, TouchableOpacity, ScrollView } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fadeInAnimation, fadeOutAnimation } from '../constants/animations';
import { useNavigate } from 'react-router-dom';
import {
  ButonSubmit,
  CasetaTextAcessKey,
  CasetaTextEmail,
  CasetaTextNumeUtilizator,
  CasetaTextParola,
  CasetaTextSecretKey,
  TitluFormular,
} from '../components/caseteText';
import { IndicatorStare } from '../components/IndicatorStare';
import { theme, themeStyles } from '../constants/themeColors1';

const CreareContPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const fadeAnimLogo = useRef(new Animated.Value(0)).current;
  const fadeAnimText = useRef(new Animated.Value(0)).current;
  const fadeAnimFormular = useRef(new Animated.Value(0)).current;
  const indicator = useSelector((state) => state.creareCont.loading);

  // La deschiderea paginii se activează animația de fade in
  useEffect(() => {
    Animated.parallel([
      fadeInAnimation(fadeAnimLogo),
      fadeInAnimation(fadeAnimText, 2000, 300),
      fadeInAnimation(fadeAnimFormular, 2000, 400),
    ]).start();
  }, []);

  const handleNavigareAutentificare = () => {
    // Se inițiază fade out pentru componentele de pe ecran
    Animated.parallel([fadeOutAnimation(fadeAnimLogo), fadeOutAnimation(fadeAnimText), fadeOutAnimation(fadeAnimFormular)]).start(() => {
      // După finalizarea animațiilor, navighează la pagina de creare cont
      navigate('/');
    });
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // REGEX PENTRU VERIFICARE EMAIL
  const parolaRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // REGEX PENTRU VERIFICARE PAROLA
  const numeUtilizatorRegex = /^[A-Z][a-z]*([- ][A-Z][a-z]*)*$/; // REGEX PENTRU VERIFICARE NUME UTILIZATOR
  const LUNGIME_ACCESS_KEY_TUYA = 20;
  const LUNGIME_SECRET_KEY_TUYA = 32;

  const [numeUtilizator, setNumeUtilizator] = useState(''); // nume de utilizator necesar pentru crearea contului
  const [emailUtilizator, setEmailUtilizator] = useState(''); // adresa de email necesare pentru crearea contului
  const [parolaUtilizator, setParolaUtilizator] = useState(''); // parola necesara pentru crearea contului
  const [showPasswordUtilizator, setShowPasswordUtilizator] = useState(false);

  const [accessKeyTuya, setAccessKeyTuya] = useState(''); // access key Tuya
  const [secretKeyTuya, setSecretKeyTuya] = useState(''); // secret key Tuya
  const [showSecretKeyTuya, setShowSecretKeyTuya] = useState(false);

  const [emailEwelink, setEmailEwelink] = useState(''); // adresa de email pentru conectarea la eWeLink
  const [parolaEwelink, setParolaEwelink] = useState(''); // parola pentru conectarea la eWeLink
  const [showPasswordEwelink, setShowPasswordEwelink] = useState(false);

  const [obiectErori, setObiectErori] = useState({
    eroareNumeUtilizator: false,
    eroareEmailUtilizator: false,
    eroareParolaUtilizator: false,
    eroareAccesKeyTuya: false,
    eroareSecretKeyTuya: false,
    eroareEmailEwelink: false,
    eroareParolaEwelink: false,
  });

  const handleCreareCont = () => {
    let mesaj = '';
    const erori = {
      eroareNumeUtilizator: !numeUtilizator || !numeUtilizatorRegex.test(numeUtilizator),
      eroareEmailUtilizator: !emailUtilizator || !emailRegex.test(emailUtilizator),
      eroareParolaUtilizator: !parolaUtilizator || !parolaRegex.test(parolaUtilizator),
      eroareAccesKeyTuya: accessKeyTuya && accessKeyTuya.length !== LUNGIME_ACCESS_KEY_TUYA,
      eroareSecretKeyTuya: secretKeyTuya && secretKeyTuya.length !== LUNGIME_SECRET_KEY_TUYA,
      eroareEmailEwelink: emailEwelink && !emailRegex.test(emailEwelink),
      eroareParolaEwelink: parolaEwelink && parolaEwelink === '',
    };

    // Generare mesaj de eroare
    if (erori.eroareNumeUtilizator) mesaj += 'Numele de utilizator nu este valid. ';
    if (erori.eroareEmailUtilizator) mesaj += 'Adresa de email nu este validă. ';
    if (erori.eroareParolaUtilizator)
      mesaj += 'Parola trebuie să conțină minim 8 caractere, inclusiv cel puțin o literă mare, o cifră și un caracter special. ';
    if (erori.eroareAccesKeyTuya) mesaj += 'Access Key Tuya nu este valid! ';
    if (erori.eroareSecretKeyTuya) mesaj += 'Secret Key Tuya nu este valid! ';
    if (erori.eroareEmailEwelink) mesaj += 'Adresa de email pentru contul eWeLink nu este validă. ';
    if (erori.eroareParolaEwelink) mesaj += 'Parola pentru contul eWeLink lipsește!';

    setObiectErori(erori);
    if (mesaj) {
      dispatch({ type: 'NOTIFICARE_EROARE', payload: mesaj });
    }

    // Resetează erorile după 2 secunde
    setTimeout(() => {
      setObiectErori({
        eroareNumeUtilizator: false,
        eroareEmailUtilizator: false,
        eroareParolaUtilizator: false,
        eroareAccesKeyTuya: false,
        eroareSecretKeyTuya: false,
        eroareEmailEwelink: false,
        eroareParolaEwelink: false,
      });
    }, 2000);

    const validNumeUtilizator = numeUtilizatorRegex.test(numeUtilizator);
    const validEmailUtilizator = emailRegex.test(emailUtilizator);
    const validParolaUtilizator = parolaRegex.test(parolaUtilizator);
    const validAccessKeyTuya = accessKeyTuya.length === LUNGIME_ACCESS_KEY_TUYA;
    const validSecretKeyTuya = secretKeyTuya.length === LUNGIME_SECRET_KEY_TUYA;
    const validEmailEwelink = emailRegex.test(emailEwelink);
    const validParolaEwelink = parolaEwelink != '';

    // Trimiterea datelor către backend
    if (
      validNumeUtilizator &&
      validEmailUtilizator &&
      validParolaUtilizator &&
      ((validAccessKeyTuya && validSecretKeyTuya) ||
        (validEmailEwelink && validParolaEwelink) ||
        (!accessKeyTuya && !secretKeyTuya && !emailEwelink && !parolaEwelink))
    ) {
      dispatch({
        type: 'CREARE_CONT_REQUEST',
        payload: {
          emailCont: emailUtilizator.trim(),
          nume_utilizator: numeUtilizator.trim(),
          parolaCont: parolaUtilizator.trim(),
          accessKeyTuya: accessKeyTuya.trim() || null,
          secretKeyTuya: secretKeyTuya.trim() || null,
          emailEwelink: emailEwelink.trim() || null,
          parolaEwelink: parolaEwelink.trim() || null,
        },
      });
    }
  };

  return (
    <View style={[themeStyles.fundal, { height: height }]}>
      <View style={themeStyles.continerPaginaLogin}>
        <View style={themeStyles.containerStanga}>
          <Animated.Image source={require('../constants/logo.png')} style={[themeStyles.iconLogin, { opacity: fadeAnimLogo }]} />
          <Animated.Text style={[themeStyles.titluLogin, { opacity: fadeAnimText }]}>Home Control - Creare Cont</Animated.Text>
          <Animated.Text style={[themeStyles.titluLogin, { opacity: fadeAnimText, fontSize: 25, fontStyle: 'italic' }]}>
            Aveți deja un cont?{' '}
            <TouchableOpacity onPress={() => handleNavigareAutentificare()}>
              <Text style={{ color: theme.accent100, textDecorationLine: 'underline' }}>Autentificați-vă aici!</Text>
            </TouchableOpacity>
          </Animated.Text>
        </View>

        {/* FORMULAR CREARE CONT */}
        <View style={themeStyles.containerStanga}>
          <Animated.View style={[themeStyles.fundalFormularLogin, { opacity: fadeAnimFormular }]}>
            <ScrollView style={{ width: '100%', height: '100%', margin: '10px' }} showsVerticalScrollIndicator={false}>
              <TitluFormular label={'Date personale (obligatoriu)'} />
              {/* CASETA INPUT NUME UTILIZATOR */}
              <CasetaTextNumeUtilizator
                label={'Nume utilizator'}
                error={obiectErori.eroareNumeUtilizator}
                value={numeUtilizator}
                setValue={setNumeUtilizator}
                fieldStyle={{ marginBottom: '10px' }}
              />
              {/* CASETA IMPUT EMAIL */}
              <CasetaTextEmail
                label={'Email'}
                error={obiectErori.eroareEmailUtilizator}
                value={emailUtilizator}
                setValue={setEmailUtilizator}
                fieldStyle={{ marginBottom: '10px' }}
              />
              {/* CASETA INPUT PAROLA */}
              <CasetaTextParola
                label={'Parola'}
                error={obiectErori.eroareParolaUtilizator}
                value={parolaUtilizator}
                setValue={setParolaUtilizator}
                showPassword={showPasswordUtilizator}
                setShowPassword={setShowPasswordUtilizator}
                fieldStyle={{ marginBottom: '15px' }}
              />

              <TitluFormular label={'Conectare cont Tuya Smart (opțional)'} />
              {/* CASETA ACCES KEY TUYA SMART */}
              <CasetaTextAcessKey
                label={'Access Key Tuya Smart'}
                error={obiectErori.eroareAccesKeyTuya}
                value={accessKeyTuya}
                setValue={setAccessKeyTuya}
                fieldStyle={{ marginBottom: '10px' }}
              />
              {/* CASETA SECRET KEY TUYA */}
              <CasetaTextSecretKey
                label={'Secret Key Tuya Smart'}
                error={obiectErori.eroareSecretKeyTuya}
                value={secretKeyTuya}
                setValue={setSecretKeyTuya}
                showSecretKey={showSecretKeyTuya}
                setShowSecretKey={setShowSecretKeyTuya}
                fieldStyle={{ marginBottom: '15px' }}
              />

              <TitluFormular label={'Conectare cont eWeLink (opțional)'} />
              {/* CASETA IMPUT EMAIL EWELINK*/}
              <CasetaTextEmail
                label={'Email cont eWeLink'}
                error={obiectErori.eroareEmailEwelink}
                value={emailEwelink}
                setValue={setEmailEwelink}
                fieldStyle={{ marginBottom: '10px' }}
              />
              {/* CASETA INPUT PAROLA EWELINK */}
              <CasetaTextParola
                label={'Parola cont eWeLink'}
                error={obiectErori.eroareParolaEwelink}
                value={parolaEwelink}
                setValue={setParolaEwelink}
                showPassword={showPasswordEwelink}
                setShowPassword={setShowPasswordEwelink}
                fieldStyle={{ marginBottom: '60px' }}
              />

              {/* BUTON CREARE CONT */}
              <ButonSubmit label={'Creare Cont'} onPress={() => handleCreareCont()} />
            </ScrollView>
          </Animated.View>
        </View>
      </View>
      <IndicatorStare vizibil={indicator} />
    </View>
  );
};

export default CreareContPage;
