import React, { useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, Animated } from 'react-native';
import {
  AcUnitRounded,
  AddRounded,
  Battery5BarRounded,
  LocalFireDepartmentRounded,
  LockRounded,
  LooksOneRounded,
  LooksTwoRounded,
  Looks3Rounded,
  Looks4Rounded,
  PowerSettingsNewRounded,
  RemoveRounded,
  WifiOffRounded,
  WifiRounded,
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { theme } from '../constants/themeColors1';

const EWELINK = 'eWeLink';
const TUYA = 'Tuya Smart';

export function CardDevice({ item, key, style, auth, opacity }) {
  const dispatch = useDispatch();
  // ELEMENT PENTRU COMANDA SI CONTROLUL PRIZELOR CU UN SINGUR CANAL
  const ElementPrizaUnCanal = ({ date }) => {
    const stare = date.stare_dispozitiv.status;
    let stare_canal = null;
    let nume_canal = null;
    let curent = 0;
    let tensiune = 0;
    let putere = 0;

    // se preiau starile canlului, curentul, tensiunea si puterea
    for (let i = 0; i < stare.length; i++) {
      if (stare[i].code === 'switch' || stare[i].code === 'switch_1') {
        stare_canal = stare[i].value;
        nume_canal = stare[i].code;
      }
      if (stare[i].code === 'cur_current') curent = stare[i].value;
      if (stare[i].code === 'cur_voltage') tensiune = stare[i].value;
      if (stare[i].code === 'cur_power') putere = stare[i].value;
    }

    const handleComandaCanal = () => {
      dispatch({
        type: 'COMANDA_DISPOZITIV_REQUEST',
        payload: {
          token: auth.token,
          id_serviciu_cloud: item.id_serviciu_cloud,
          date_dispozitiv: {
            id_dispozitiv: item.id_cloud_dispozitiv,
            comanda: {
              commands: [{ code: nume_canal, value: !stare_canal }],
            },
          },
        },
      });
    };

    return (
      <View style={[styles.containerDateCloud, { flexDirection: 'row' }]}>
        {/* AFISARE VALORI CUTENT, TENSIUNE SI PUTERE */}
        <View style={{ width: '60%', height: '100%', justifyContent: 'center' }}>
          {stare_canal && (
            <>
              <Text style={styles.textValori}>Curent: {curent / 1000} A</Text>
              <Text style={styles.textValori}>Tensiune: {tensiune / 10} V</Text>
              <Text style={styles.textValori}>Putere: {putere / 10} W</Text>
            </>
          )}
        </View>
        {/* BUTON COMANDA SI CONTROL */}
        <TouchableOpacity
          style={{ width: '40%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
          onPress={() => handleComandaCanal()}
        >
          <PowerSettingsNewRounded sx={{ width: 45, height: 45, color: stare_canal ? theme.text100 : theme.bg300 }} />
          <Text style={{ color: theme.text100, marginTop: 5, fontSize: 12 }}>Canal: {stare_canal ? 'on' : 'off'}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  // ELEMENT PENTRU COMANDA SI CONTROLUL PRIZELOR CU DOUA CANALE
  const ElementPrizaDouaCanale = ({ date }) => {
    const stare = date.stare_dispozitiv.status;
    let canal1 = null;
    let canal2 = null;
    let curent = 0;
    let tensiune = 0;
    let putere = 0;

    // se preiau starile canlului, curentul, tensiunea si puterea
    for (let i = 0; i < stare.length; i++) {
      if (stare[i].code === 'switch_1') canal1 = stare[i].value;
      if (stare[i].code === 'switch_2') canal2 = stare[i].value;
      if (stare[i].code === 'cur_current') curent = stare[i].value;
      if (stare[i].code === 'cur_voltage') tensiune = stare[i].value;
      if (stare[i].code === 'cur_power') putere = stare[i].value;
    }

    const ButonCanal = ({ nume, onPress, canal }) => {
      return (
        <TouchableOpacity onPress={onPress} style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
          <PowerSettingsNewRounded sx={{ width: 25, height: 25, color: canal ? theme.text100 : theme.bg300 }} />
          <Text style={{ color: theme.text100, marginLeft: 10, fontSize: 12 }} adjustsFontSizeToFit={true}>
            {nume}: {canal ? 'on' : 'off'}
          </Text>
        </TouchableOpacity>
      );
    };

    const handleComandaCanal = (canal, stare) => {
      dispatch({
        type: 'COMANDA_DISPOZITIV_REQUEST',
        payload: {
          token: auth.token,
          id_serviciu_cloud: item.id_serviciu_cloud,
          date_dispozitiv: {
            id_dispozitiv: item.id_cloud_dispozitiv,
            comanda: { commands: [{ code: canal, value: stare }] },
          },
        },
      });
    };

    return (
      <View style={[styles.containerDateCloud, { flexDirection: 'row' }]}>
        {/* AFISARE VALORI CUTENT, TENSIUNE SI PUTERE */}
        <View style={{ width: '60%', height: '100%', justifyContent: 'center' }}>
          {(canal1 || canal2) && (
            <>
              <Text style={styles.textValori} adjustsFontSizeToFit={true}>
                Curent: {curent / 1000} A
              </Text>
              <Text style={styles.textValori} adjustsFontSizeToFit={true}>
                Tensiune: {tensiune / 10} V
              </Text>
              <Text style={styles.textValori} adjustsFontSizeToFit={true}>
                Putere: {putere / 10} W
              </Text>
            </>
          )}
        </View>
        <View style={{ width: '40%', height: '100%', justifyContent: 'center' }}>
          <ButonCanal nume={'Canal 1'} canal={canal1} onPress={() => handleComandaCanal('switch_1', !canal1)} />
          <ButonCanal nume={'Canal 2'} canal={canal2} onPress={() => handleComandaCanal('switch_2', !canal2)} />
        </View>
      </View>
    );
  };

  // ELEMENT PENTRU SENZORII DE TEMPERATURA SI UMIDITATE
  const ElementTempUmid = ({ date }) => {
    // se determina starea bateriei
    let baterie = date.stare_dispozitiv.status[2].value;
    let nivelBaterie = baterie === 'low' ? 'scăzut' : baterie === 'middle' ? 'mediu' : 'maxim';
    return (
      <View style={styles.containerDateCloud}>
        <View style={{ flexDirection: 'row', width: '100%', height: '80%' }}>
          {/* jumatate in care este afisata temperatura */}
          <View style={styles.jumSectiune}>
            <Text style={styles.textTemperatura}>{date.stare_dispozitiv.status[0].value / 10}°C</Text>
            <Text style={styles.subsolTemp}>Temperatură</Text>
          </View>

          {/* jumatate in care este afisata umiditatea */}
          <View style={styles.jumSectiune}>
            <Text style={styles.textTemperatura}>{date.stare_dispozitiv.status[1].value}%</Text>
            <Text style={styles.subsolTemp}>Umiditate</Text>
          </View>
        </View>
        {/* nivelul bateriei */}
        <View style={{ width: '100%', height: '20%', justifyContent: 'center' }}>
          <Text style={{ color: baterie === 'low' ? 'red' : theme.text100, marginLeft: 15, fontSize: 12 }}>Nivel baterie: {nivelBaterie}</Text>
        </View>
      </View>
    );
  };

  // ELEMENT PENTRU COMANDA SI CONTROLUL INTRERUPATORELOR CU DOUA CANALE
  const ElementIntrerupatorDouaCanale = ({ date }) => {
    // starile celor doua canale
    const switch1 = date.stare_dispozitiv.status[0].value;
    const switch2 = date.stare_dispozitiv.status[1].value;

    const handleComandaIntrerupatorMaster = (master, stare) => {
      dispatch({
        type: 'COMANDA_DISPOZITIV_REQUEST',
        payload: {
          token: auth.token,
          id_serviciu_cloud: item.id_serviciu_cloud,
          date_dispozitiv: {
            id_dispozitiv: item.id_cloud_dispozitiv,
            comanda: {
              commands: [
                { code: 'switch_1', value: stare },
                { code: 'switch_2', value: stare },
              ],
            },
          },
        },
      });
    };

    const handleComandaCanal = (canal, stare) => {
      dispatch({
        type: 'COMANDA_DISPOZITIV_REQUEST',
        payload: {
          token: auth.token,
          id_serviciu_cloud: item.id_serviciu_cloud,
          date_dispozitiv: {
            id_dispozitiv: item.id_cloud_dispozitiv,
            comanda: { commands: [{ code: canal, value: stare }] },
          },
        },
      });
    };

    return (
      <View style={[styles.containerDateCloud, { flexDirection: 'row' }]}>
        {/* BUTON MASTER PENTRU PORNIREA AMBELOR CANALE */}
        <TouchableOpacity style={styles.treimeSectiune} onPress={() => handleComandaIntrerupatorMaster(true, !(switch1 && switch2))}>
          <PowerSettingsNewRounded sx={{ width: 45, height: 45, color: switch1 && switch2 ? theme.text100 : theme.bg300 }} />
          <Text style={{ color: theme.text100, marginTop: 5, fontSize: 12 }}>Master: {switch1 && switch2 ? 'on' : 'off'}</Text>
        </TouchableOpacity>

        {/* BUTON SWITCH CANAL 1 */}
        <TouchableOpacity style={styles.treimeSectiune} onPress={() => handleComandaCanal('switch_1', !switch1)}>
          <PowerSettingsNewRounded sx={{ width: 45, height: 45, color: switch1 ? theme.text100 : theme.bg300 }} />
          <Text style={{ color: theme.text100, marginTop: 5, fontSize: 12 }}>Canal 1: {switch1 ? 'on' : 'off'}</Text>
        </TouchableOpacity>

        {/* BUTON SWITCH CANAL 2 */}
        <TouchableOpacity style={styles.treimeSectiune} onPress={() => handleComandaCanal('switch_2', !switch2)}>
          <PowerSettingsNewRounded sx={{ width: 45, height: 45, color: switch2 ? theme.text100 : theme.bg300 }} />
          <Text style={{ color: theme.text100, marginTop: 5, fontSize: 12 }}>Canal 2: {switch2 ? 'on' : 'off'}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  // ELEMENT PENTRU COMANDA SI CONTROLUL RELEELOR SONOFF (EWELINK)
  const ElementReleu = ({ date }) => {
    const stareReleu = date.params.switch === 'on' ? true : false;
    const handleToggleReleu = () => {
      dispatch({
        type: 'COMANDA_DISPOZITIV_REQUEST',
        payload: {
          token: auth.token,
          id_serviciu_cloud: item.id_serviciu_cloud,
          date_dispozitiv: {
            id_dispozitiv: item.id_cloud_dispozitiv,
          },
        },
      });
    };
    return (
      <View style={[styles.containerDateCloud]}>
        <TouchableOpacity style={{ alignSelf: 'center', alignItems: 'center' }} onPress={() => handleToggleReleu()}>
          <PowerSettingsNewRounded sx={{ width: 50, height: 50, color: stareReleu ? theme.text100 : theme.bg300 }} />
          <Text style={{ color: theme.text100, marginTop: 5, fontSize: 12 }}>Stare releu: {stareReleu ? 'on' : 'off'}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  // ELEMENT PENTRU COMANDA SI AFISAREA TERMOSTATULUI WIRSEN
  const ElementTermostatWirsen = ({ date }) => {
    const stareDispozitiv = date.stare_dispozitiv.status;
    const stareSwitch = stareDispozitiv[0].value;
    const stareFrost = stareDispozitiv[2].value;
    const stareBlocajCopii = stareDispozitiv[10].value;
    const temperaturaMinima = stareDispozitiv[6].value;
    const temperaturaMaxima = stareDispozitiv[4].value;
    const temperaturaSetata = stareDispozitiv[3].value;
    const valva = stareDispozitiv[8].value;

    const ButonComanda = ({ icon, onPress }) => {
      return <TouchableOpacity onPress={onPress}>{icon}</TouchableOpacity>;
    };
    let temperaturaNoua = temperaturaSetata;
    const handleTemperatura = (comanda) => {
      // se creste temperatura
      if (comanda === '+' && temperaturaSetata < temperaturaMaxima && temperaturaSetata > temperaturaMinima) {
        temperaturaNoua = temperaturaNoua + 1;
      } else if (comanda === '-' && temperaturaSetata < temperaturaMaxima && temperaturaSetata > temperaturaMinima) {
        temperaturaNoua = temperaturaNoua - 1;
      }

      setTimeout(() => {
        dispatch({
          type: 'COMANDA_DISPOZITIV_REQUEST',
          payload: {
            token: auth.token,
            id_serviciu_cloud: item.id_serviciu_cloud,
            date_dispozitiv: {
              id_dispozitiv: item.id_cloud_dispozitiv,
              comanda: { commands: [{ code: 'temp_set', value: temperaturaNoua }] },
            },
          },
        });
      }, 1000);
    };

    return (
      <View style={styles.containerDateCloud}>
        <View style={{ flexDirection: 'row', width: '100%', height: '65%' }}>
          {/* TEMPERATURILE APAR DOAR CAND TERMOSTATUL ESTE PORNIT */}
          {stareSwitch ? (
            <>
              {/* afisare temperatura curenta */}
              <View style={styles.sectiuneTermostat}>
                <Text style={styles.textTemperatura}>{stareDispozitiv[5].value / 10}°C</Text>
                <Text style={styles.subsolTemp}>Temp. curentă</Text>
              </View>

              {/* afisare temperatura setata */}
              <View style={styles.sectiuneTermostat}>
                <Text style={styles.textTemperatura}>{temperaturaSetata}°C</Text>
                <Text style={styles.subsolTemp}>Temp. setată</Text>
              </View>

              {/* afisare stare valva */}
              <View style={[styles.sectiuneTermostat, { width: '10%' }]}>
                <LocalFireDepartmentRounded
                  sx={{ width: 30, height: 30, color: valva === 'open' ? theme.text100 : valva === 'close' && theme.accent100 }}
                />
              </View>
            </>
          ) : (
            <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ color: theme.text100, fontWeight: '600', fontSize: 18 }}>Termostatul este oprit!</Text>
            </View>
          )}
        </View>

        {/* butoane setari */}
        <View style={{ width: '100%', height: '35%', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
          {/* BUTON ON/OFF */}
          <ButonComanda
            icon={<PowerSettingsNewRounded sx={{ width: 30, height: 30, color: stareSwitch ? theme.text100 : theme.bg300 }} />}
            onPress={() => {
              dispatch({
                type: 'COMANDA_DISPOZITIV_REQUEST',
                payload: {
                  token: auth.token,
                  id_serviciu_cloud: item.id_serviciu_cloud,
                  date_dispozitiv: {
                    id_dispozitiv: item.id_cloud_dispozitiv,
                    comanda: { commands: [{ code: 'switch', value: !stareSwitch }] },
                  },
                },
              });
            }}
          />

          {/* BUTOANELE APAR DOAR DACA TERMOSTATUL ESTE PORNIT */}
          {stareSwitch && (
            <>
              {/* BUTOANE PENTRU SETARE TEMPERATURA */}
              <ButonComanda icon={<AddRounded sx={{ width: 30, height: 30, color: theme.text100 }} />} onPress={() => handleTemperatura('+')} />
              <ButonComanda icon={<RemoveRounded sx={{ width: 30, height: 30, color: theme.text100 }} />} onPress={() => handleTemperatura('-')} />

              {/* BUTON FROST */}
              <ButonComanda
                icon={<AcUnitRounded sx={{ width: 30, height: 30, color: stareFrost ? theme.text100 : theme.bg300 }} />}
                onPress={() => {
                  dispatch({
                    type: 'COMANDA_DISPOZITIV_REQUEST',
                    payload: {
                      token: auth.token,
                      id_serviciu_cloud: item.id_serviciu_cloud,
                      date_dispozitiv: {
                        id_dispozitiv: item.id_cloud_dispozitiv,
                        comanda: { commands: [{ code: 'frost', value: !stareFrost }] },
                      },
                    },
                  });
                }}
              />
              {/* BUTON CHILD LOCK */}
              <ButonComanda
                icon={<LockRounded sx={{ width: 30, height: 30, color: stareBlocajCopii ? theme.text100 : theme.bg300 }} />}
                onPress={() => {
                  dispatch({
                    type: 'COMANDA_DISPOZITIV_REQUEST',
                    payload: {
                      token: auth.token,
                      id_serviciu_cloud: item.id_serviciu_cloud,
                      date_dispozitiv: {
                        id_dispozitiv: item.id_cloud_dispozitiv,
                        comanda: { commands: [{ code: 'child_lock', value: !stareBlocajCopii }] },
                      },
                    },
                  });
                }}
              />
            </>
          )}
        </View>
      </View>
    );
  };

  // ELEMENT PENTRU AFISARE SENZORI USI/FERESTRE
  const ElementContactUsaFereastra = ({ date }) => {
    const stareDispozitiv = date.stare_dispozitiv.status;
    const stareContact = stareDispozitiv[0].value;
    return (
      <View style={[styles.containerDateCloud, { flexDirection: 'row', justifyContent: 'flex-start' }]}>
        <View style={[styles.afisajBaterie]}>
          <Battery5BarRounded sx={{ width: 20, height: 20, color: theme.text100 }} />
          <Text style={{ color: theme.text100, marginLeft: 3 }}>{stareDispozitiv[1].value}%</Text>
        </View>
        <View style={[styles.jumSectiune, { width: '60%' }]}>
          <Image source={stareContact ? require(`../assets/open.png`) : require(`../assets/close.png`)} style={{ width: 85, height: 70 }} />
          <Text style={{ color: theme.text100, fontSize: 12 }}>{!stareContact ? 'Contact închis' : 'Contact deschis'}</Text>
        </View>
      </View>
    );
  };

  // ELEMENT PENTRU COMANDA SI CONTROL BANDA LED QC0383_P5_QMCBU_STRIP3_RGB
  const ElementBandaLED = ({ date }) => {
    const stareDispozitiv = date.stare_dispozitiv.status;
    const stareSwitch = stareDispozitiv[0].value;

    return (
      <View style={styles.containerDateCloud}>
        <TouchableOpacity
          style={{ alignSelf: 'center', alignItems: 'center' }}
          onPress={() => {
            dispatch({
              type: 'COMANDA_DISPOZITIV_REQUEST',
              payload: {
                token: auth.token,
                id_serviciu_cloud: item.id_serviciu_cloud,
                date_dispozitiv: {
                  id_dispozitiv: item.id_cloud_dispozitiv,
                  comanda: { commands: [{ code: 'switch_led', value: !stareSwitch }] },
                },
              },
            });
          }}
        >
          <PowerSettingsNewRounded sx={{ width: 50, height: 50, color: stareSwitch ? theme.text100 : theme.bg300 }} />
          <Text style={{ color: theme.text100, marginTop: 5, fontSize: 12 }}>Stare: {stareSwitch ? 'on' : 'off'}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  // ELEMENT COMANDA PURIFICATOR P40 SMART
  const ElementPurificator = ({ date }) => {
    const stareDispozitiv = date.stare_dispozitiv.status;
    const stareSwitch = stareDispozitiv[0].value;
    const stareAnion = stareDispozitiv[3].value;
    const stareUV = stareDispozitiv[5].value;
    const stareSleep = stareDispozitiv[4].value;
    const nivel = stareDispozitiv[2].value;

    // buton reutilizabil pentru comenzi
    const ButonComanda = ({ nume, onPress, activ }) => {
      return (
        <View style={{ justifyContent: 'center', alignItems: 'center', marginLeft: 15, marginRight: 15, marginBottom: 2 }}>
          <TouchableOpacity
            style={{
              backgroundColor: activ ? theme.accent200 : theme.primary100,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 100,
              padding: 5,
            }}
            onPress={onPress}
          >
            <Image source={require(`../assets/${nume}.png`)} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>
          <Text style={{ fontSize: 10, color: theme.text100, marginTop: 2 }}>{activ ? 'On' : 'Off'}</Text>
        </View>
      );
    };

    // buton reutilizabil pentru setare nivel
    const ButonNivel = ({ valoare, onPress, Icon }) => {
      return (
        <TouchableOpacity onPress={onPress}>
          <Icon sx={{ fontSize: 30, color: valoare === nivel ? theme.text100 : theme.primary100 }} />
        </TouchableOpacity>
      );
    };

    const handleComanda = (comanda) => {
      dispatch({
        type: 'COMANDA_DISPOZITIV_REQUEST',
        payload: {
          token: auth.token,
          id_serviciu_cloud: item.id_serviciu_cloud,
          date_dispozitiv: {
            id_dispozitiv: item.id_cloud_dispozitiv,
            comanda: comanda,
          },
        },
      });
    };
    return stareSwitch ? (
      <View style={[styles.containerDateCloud, { flexDirection: 'row' }]}>
        <View style={{ width: '50%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          {/* afisare PM2.5 */}
          <View style={{ width: '100%', height: '60%', justifyContent: 'center', alignItems: 'center' }}>
            <Text style={styles.textTemperatura}>{stareDispozitiv[1].value / 10}</Text>
            <Text style={styles.subsolTemp}>PM2.5</Text>
          </View>
          {/* butoane care indica nivelul de viteza */}
          <View style={{ width: '100%', height: '40%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
            <ButonNivel Icon={LooksOneRounded} valoare={'1'} onPress={() => handleComanda({ commands: [{ code: 'speed', value: '1' }] })} />
            <ButonNivel Icon={LooksTwoRounded} valoare={'2'} onPress={() => handleComanda({ commands: [{ code: 'speed', value: '2' }] })} />
            <ButonNivel Icon={Looks3Rounded} valoare={'3'} onPress={() => handleComanda({ commands: [{ code: 'speed', value: '3' }] })} />
            <ButonNivel Icon={Looks4Rounded} valoare={'4'} onPress={() => handleComanda({ commands: [{ code: 'speed', value: '4' }] })} />
          </View>
        </View>
        {/* afisare controale */}
        <View style={{ width: '50%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          {/* Buton ON/OFF si activare/dezacticare ionizare */}
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
            <ButonComanda nume={'power'} activ={stareSwitch} onPress={() => handleComanda({ commands: [{ code: 'switch', value: !stareSwitch }] })} />
            <ButonComanda nume={'anion'} activ={stareAnion} onPress={() => handleComanda({ commands: [{ code: 'anion', value: !stareAnion }] })} />
          </View>
          {/* Butoane UV si Sleep */}
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
            <ButonComanda nume={'uv'} activ={stareUV} onPress={() => handleComanda({ commands: [{ code: 'uv', value: !stareUV }] })} />
            <ButonComanda nume={'sleep'} activ={stareSleep} onPress={() => handleComanda({ commands: [{ code: 'light', value: !stareSleep }] })} />
          </View>
        </View>
      </View>
    ) : (
      <View style={styles.containerDateCloud}>
        <TouchableOpacity
          style={{ alignSelf: 'center', alignItems: 'center' }}
          onPress={() => handleComanda({ commands: [{ code: 'switch', value: !stareSwitch }] })}
        >
          <PowerSettingsNewRounded sx={{ width: 50, height: 50, color: theme.bg300 }} />
          <Text style={{ color: theme.text100, marginTop: 5, fontSize: 12 }}>Stare: off</Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <Animated.View key={key} style={[style, { opacity: opacity }]}>
      <View style={{ width: '98%', height: '92%', padding: 10, alignSelf: 'center' }}>
        {/* DENUMIREA DISPOZITIVULUI PRELUATA DIN BAZA DE DATE SI ICON-UL SPECIFIC */}
        <View style={styles.margini}>
          <Image source={require(`../assets/${item.tip_dispozitiv}.png`)} style={{ width: 25, height: 25, marginLeft: 10 }} />
          <Text style={{ color: theme.text100, fontSize: 16, fontWeight: '600' }}>{item.nume_dispozitiv_db}</Text>
        </View>
        {/* AICI SE AFISEAZA INFORMATIILE PRELUATE DIN CLOUD */}
        {item.online ? (
          <View style={styles.centru}>
            {/* AFISARE BUTON COMANDA SI CONTROL PENTRU PRIZE CU UN SINGUR CANAL */}
            {item.id_tip_dispozitiv === 1 && <ElementPrizaUnCanal date={item.date_cloud} />}
            {/* AFISARE BUTON COMANDA SI CONTROL PENTRU PRIZE CU UN SINGUR CANAL */}
            {item.id_tip_dispozitiv === 2 && <ElementPrizaDouaCanale date={item.date_cloud} />}
            {/* AFISARE BUTON COMANDA SI CONTROL RELEU SONOFF */}
            {item.id_tip_dispozitiv === 3 && <ElementReleu date={item.date_cloud} />}
            {/* AFISARE DATE SENZOR UMIDITATE SI TEMPERATURA */}
            {item.id_tip_dispozitiv === 4 && <ElementTempUmid date={item.date_cloud} />}
            {/* AFISARE BUTOANE COMANDA SI CONTROL PENTRU INTRERUPATORUL CU DOUA CANALE */}
            {item.id_tip_dispozitiv === 5 && <ElementIntrerupatorDouaCanale date={item.date_cloud} />}
            {/* AFISARE INTERFATA TERMOSTAT WIRSEN */}
            {item.id_tip_dispozitiv === 6 && <ElementTermostatWirsen date={item.date_cloud} />}
            {/* AFISEAZA  SENZORUL PENTRU USA/FEREASTRA */}
            {item.id_tip_dispozitiv === 7 && <ElementContactUsaFereastra date={item.date_cloud} />}
            {/* AFISEAZA CONTROALE BANDA LED QC0383_P5_QMCBU_STRIP3_RGB */}
            {item.id_tip_dispozitiv === 8 && <ElementBandaLED date={item.date_cloud} />}
            {/* AFISARE CONTROALE PURIFICATOR P40 SNMART */}
            {item.id_tip_dispozitiv === 9 && <ElementPurificator date={item.date_cloud} />}
          </View>
        ) : (
          // MESAJ CARE APARA PENTRU DISPOZITIVELE OFFLINE
          <View style={[styles.centru, { justifyContent: 'center', alignItems: 'center' }]}>
            <Text style={{ color: theme.text100, fontWeight: '600', fontSize: 18 }}>Dispozitivul este offline!</Text>
          </View>
        )}
        {/* GRUPUL, DENUMIREA DIN CLOUD SI INDICATORUL DE ONLINE/OFFLINE */}
        <View style={styles.margini}>
          <Text style={{ color: theme.text200, fontStyle: 'italic' }}>
            {item.denumire_grup} - {item.nume_dispozitiv_cloud}
          </Text>
          {item.online ? <WifiRounded sx={styles.iconWifi} /> : <WifiOffRounded sx={styles.iconWifi} />}
        </View>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  margini: {
    width: '100%',
    height: '20%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },

  centru: {
    width: '100%',
    height: '60%',
  },

  iconWifi: {
    color: theme.text100,
    width: 20,
    height: 20,
  },

  containerDateCloud: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },

  jumSectiune: {
    width: '50%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  afisajBaterie: {
    height: '100%',
    width: '20%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },

  sectiuneTermostat: {
    width: '45%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  textTemperatura: {
    color: theme.text100,
    fontSize: 30,
    fontWeight: '500',
    marginBottom: 5,
  },

  subsolTemp: {
    color: theme.text100,
    fontSize: 13,
    fontStyle: 'italic',
  },

  treimeSectiune: {
    width: '33.33%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  textValori: {
    color: theme.text100,
    marginLeft: 10,
    marginBottom: 5,
    fontSize: 12,
    fontWeight: '500',
  },
});
