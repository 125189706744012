import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';
import { navigateTo } from '../context/NavigationContext';

// Worker saga
function* creareCont(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'creare_cont');
    if (response.error) {
      yield put({ type: 'CREARE_CONT_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'CREARE_CONT_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });

      // se navigeaza catre pagina de autentificare
      navigateTo('/');
    }
  } catch (error) {
    yield put({ type: 'CREARE_CONT_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchCreareCont() {
  yield takeLatest('CREARE_CONT_REQUEST', creareCont);
}

export default watchCreareCont;
