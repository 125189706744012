import { View, Text, Image, useWindowDimensions } from 'react-native';
import React from 'react';
import { theme } from '../constants/themeColors1';

export function EcranMobil() {
  const { height, width } = useWindowDimensions();
  return (
    <View style={{ width, height, backgroundColor: theme.bg300, justifyContent: 'center' }}>
      <Image source={require('../constants/logo.png')} style={{ width: 100, height: 100, alignSelf: 'center', marginBottom: 20 }} />
      <Text style={{ width: '90%', alignSelf: 'center', fontSize: 15, color: theme.text100, textAlign: 'center' }}>
        Sistemul Home Control Assistant nu este diponibil pe mobil, încercați pe Desktop sau Tabletă.
      </Text>
    </View>
  );
}
