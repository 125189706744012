import React, { createContext, useContext } from 'react';
import { useSnackbar } from 'notistack';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  return <NotificationContext.Provider value={{ enqueueSnackbar }}>{children}</NotificationContext.Provider>;
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
