import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';

// Worker saga
function* comandaDispozitiv(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'comanda_dispozitiv');
    if (response.error) {
      yield put({ type: 'COMANDA_DISPOZITIV_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'COMANDA_DISPOZITIV_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });
    }
  } catch (error) {
    yield put({ type: 'COMANDA_DISPOZITIV_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchComandaDispozitiv() {
  yield takeLatest('COMANDA_DISPOZITIV_REQUEST', comandaDispozitiv);
}

export default watchComandaDispozitiv;
