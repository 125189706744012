import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';

// Worker saga
function* getDispozitive(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'preluare_dispozitive');
    if (response.error) {
      yield put({ type: 'GET_DISPOZITIVE_FAILURE', payload: response.message });
      yield put({ type: 'LOGOUT_REQUEST', payload: { token: null } });
    } else {
      yield put({ type: 'GET_DISPOZITIVE_SUCCESS', payload: response });
    }
  } catch (error) {
    yield put({ type: 'GET_DISPOZITIVE_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchGetDispozitive() {
  yield takeLatest('GET_DISPOZITIVE_REQUEST', getDispozitive);
}

export default watchGetDispozitive;
