import { View, ActivityIndicator, Text } from 'react-native';
import { theme } from '../constants/themeColors1';

export const IndicatorStare = ({ vizibil = false }) => {
  return (
    vizibil && (
      <View style={{ width: '100%', height: '100%', position: 'absolute', justifyContent: 'center' }}>
        <View style={{ width: '100%', height: '100%', opacity: 0.7, position: 'absolute', top: 0, backgroundColor: theme.bg100 }} />
        <ActivityIndicator size={100} color={theme.accent100} />
        <Text style={{ color: theme.text100, alignSelf: 'center', marginTop: 30, fontSize: 18 }}>Cerere în curs de procesare...</Text>
      </View>
    )
  );
};
