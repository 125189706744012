import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';

// Worker saga
function* getDateCont(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'preluare_date_cont');
    if (response.error) {
      yield put({ type: 'DATE_CONT_FAILURE', payload: response.message });
      // daca nu exista date se realizeaza deconecatareaz
      yield put({ type: 'LOGOUT_REQUEST', payload: { token: null } });
      // yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'DATE_CONT_SUCCESS', payload: response.response });
      // yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });
    }
  } catch (error) {
    yield put({ type: 'DATE_CONT_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchGetDateCont() {
  yield takeLatest('DATE_CONT_REQUEST', getDateCont);
}

export default watchGetDateCont;
