import React from 'react';
import { Text, TouchableOpacity, Animated } from 'react-native';
import { theme, themeStyles } from '../constants/themeColors1';
import { View } from 'react-native-web';

export const ButonReutilizabil = ({ label, icon, onPress, selected, culoareButonSelectat = null, culoareTextSelectat = null, opacity }) => {
  return (
    <Animated.View
      style={[
        themeStyles.butonGrup,
        { backgroundColor: selected ? theme.primary100 : culoareButonSelectat ? culoareButonSelectat : theme.bg300, opacity: opacity },
      ]}
    >
      <TouchableOpacity onPress={onPress} style={{ width: '100%', height: '100%', flexDirection: 'row' }}>
        {icon}
        <Text style={[themeStyles.textButonGrup, { color: selected ? theme.text100 : culoareTextSelectat ? culoareTextSelectat : theme.bg200 }]}>
          {label}
        </Text>
      </TouchableOpacity>
    </Animated.View>
  );
};
