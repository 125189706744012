import { all } from 'redux-saga/effects';
import watchLogin from './AuthSagas';
import watchCreareCont from './CreareContSagas';
import watchDeconectare from './DeconectareSagas';
import watchGetDateCont from './GetDateContSagas';
import watchEditareContUtilizator from './PostContUtilizatorSagas';
import watchSchimbareParola from './PostSchimbareParola';
import watchStergereCont from './StergereContSagas';
import watchStergereCredentiale from './StergereCredentialeSagas';
import watchAdaugareCredentiale from './PostCredentialeSagas';
import watchAdaugareDispozitiv from './PostAddDispozitivSagas';
import watchGetDispozitive from './GetDispozitiveSagas';
import watchEditareDispozitiv from './PostEditareDispozitivSagas';
import watchStergereDispozitiv from './PostStergereDispozitivSagas';
import watchStergereGrup from './PostStergereGrupDispozitiveSagas';
import watchReordonare from './ReordonareSagas';
import watchComandaDispozitiv from './PostComandaDispozitivSagas';

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchCreareCont(),
    watchDeconectare(),
    watchGetDateCont(),
    watchEditareContUtilizator(),
    watchSchimbareParola(),
    watchStergereCont(),
    watchStergereCredentiale(),
    watchAdaugareCredentiale(),
    watchAdaugareDispozitiv(),
    watchGetDispozitive(),
    watchEditareDispozitiv(),
    watchStergereDispozitiv(),
    watchStergereGrup(),
    watchReordonare(),
    watchComandaDispozitiv(),
    // alte saga-uri aici
  ]);
}
