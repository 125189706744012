import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from '../sagas/rootSaga';

// Creează middleware-ul saga
const sagaMiddleware = createSagaMiddleware();

// Creează magazinul Redux cu middleware-ul saga
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// Rulează saga
sagaMiddleware.run(rootSaga);

export default store;
