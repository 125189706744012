import { View, Text, Animated } from 'react-native';
import React, { useState, useEffect } from 'react';
import {
  ButonSubmit,
  ButonSubmitMic,
  CasetaTextAcessKey,
  CasetaTextEmail,
  CasetaTextNumeUtilizator,
  CasetaTextParola,
  CasetaTextSecretKey,
  TitluFormular,
} from './caseteText';
import { useDispatch, useSelector } from 'react-redux';
import DialogAlerta from './DialogAlerta';
import { CloudOff } from '@mui/icons-material';
import { IndicatorStare } from './IndicatorStare';
import { theme, themeStyles } from '../constants/themeColors1';

const LUNGIME_ACCESS_KEY_TUYA = 20;
const LUNGIME_SECRET_KEY_TUYA = 32;
const EWELINK = 'eWeLink';
const TUYA = 'Tuya Smart';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // REGEX PENTRU VERIFICARE EMAIL
const parolaRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // REGEX PENTRU VERIFICARE PAROLA
const numeUtilizatorRegex = /^[A-Z][a-z]*([- ][A-Z][a-z]*)*$/; // REGEX PENTRU VERIFICARE NUME UTILIZATOR

// FORMULAR PENTRU EDITARE DATE CONT UTILIZATOR
export function FormularEdidareCont({ fadeAnimation }) {
  // state-uri pentru schimbarea parolei
  const auth = useSelector((state) => state.auth.user);
  const dateCont = useSelector((state) => state.dateCont.dateContUtilizator);

  // state-uri utilizate pentru editare date utilizator
  const [numeUtilizator, setNumeUtilizator] = useState('');
  const [email, setEmail] = useState('');

  // state-uri utilizate pentru schimbarea parolei
  const [parolaVeche, setParolaVeche] = useState('');
  const [parolaNoua, setParolaNoua] = useState('');
  const [showParolaVeche, setShowParolaVeche] = useState(false);
  const [showParolaNoua, setShowParolaNoua] = useState(false);

  // state-uri pentru stergere cont
  const [parolaActuala, setParolaActuala] = useState('');
  const [showParolaActuala, setShowParolaActuala] = useState(false);

  // state-uri responsabile cu afisarea dialogurilor de confirmare a actiunilor
  const [modalStergereContVizibil, setModalStergereContVizibil] = useState(false);
  const [modalStergereCredentialeTuyaVizibil, setModalStergereCredentialeTuyaVizibil] = useState(false);
  const [modalStergereCredentialeEwelinkVizibil, setModalStergereCredentialeEwelinkVizibil] = useState(false);

  const dispatch = useDispatch();

  // functie pentru stergere cont
  const handleStergereCont = () => {
    // se trimit datele catre server
    dispatch({
      type: 'STERGERE_CONT_REQUEST',
      payload: { token: auth.token, parola: parolaActuala.trim() },
    });

    // se reseteaza caseta text
    setParolaActuala('');
    setShowParolaActuala(false);
  };

  // functie pentru stergere credentiale
  const handleStergereCredentiale = (serviciu) => {
    dispatch({
      type: 'STERGERE_CREDENTIALE_REQUEST',
      payload: { token: auth.token, serviciu },
    });
  };

  useEffect(() => {
    if (dateCont) {
      setNumeUtilizator(dateCont.nume_utilizator);
      setEmail(dateCont.email_utilizator);
    }
  }, [dateCont]);

  const handleReset = () => {
    setNumeUtilizator(dateCont.nume_utilizator);
    setEmail(dateCont.email_utilizator);
    setParolaNoua('');
    setParolaVeche('');
  };

  return (
    auth &&
    dateCont && (
      <Animated.View style={[themeStyles.formularSetariCont, { opacity: fadeAnimation, flexDirection: 'row', justifyContent: 'space-between' }]}>
        {/* FORMULARUL CU CAMPURILE PENTRU EDITAREA CONTULUI */}
        <View style={{ width: '49%', height: '100%' }}>
          <View style={themeStyles.formularEdiareSetari}>
            <TitluFormular label={'Editare date cont'} />
            {/* CASETA INPUT PENTRU SCHIMBARE NUME UTILIZATOR */}
            <CasetaTextNumeUtilizator
              label={'Nume utilizator'}
              value={numeUtilizator}
              setValue={setNumeUtilizator}
              fieldStyle={{ marginBottom: 20, marginTop: 5 }}
            />
            {/* CASETA INPUT PENTRU SCHIMBARE EMAIL */}
            <CasetaTextEmail label={'Email'} value={email} setValue={setEmail} />
            {/* CONTAINER CU BUTON CONFIRMARE EDITARE DATE SI BUTON RESETARE DATE */}
            <View
              style={{ width: '80%', flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', position: 'absolute', bottom: 20 }}
            >
              <ButonSubmitMic
                label={'Salvare schimbări'}
                onPress={() =>
                  dispatch({
                    type: 'POST_CONT_REQUEST',
                    payload: { email: email.trim(), nume_utilizator: numeUtilizator.trim(), token: auth.token },
                  })
                }
                // butonul este dezactivat daca campurile nu sunt completate corect
                disabled={!(emailRegex.test(email) && numeUtilizatorRegex.test(numeUtilizator))}
              />
              <ButonSubmitMic label={'Resetare'} onPress={() => handleReset()} />
            </View>
          </View>

          {/* SPATIU INTRE ELEMENTE */}
          <View style={{ width: '100%', height: '2%' }} />

          {/* FORMULARUL CU PAMPURILE PENTRU SCHIMBAREA PAROLEI */}
          <View style={themeStyles.formularEdiareSetari}>
            <TitluFormular label={'Schimbare parolă'} />
            {/* CASETA INPUT PAROLA VECHE */}
            <CasetaTextParola
              label={'Parola actuală'}
              value={parolaVeche}
              setValue={setParolaVeche}
              showPassword={showParolaVeche}
              setShowPassword={setShowParolaVeche}
              fieldStyle={{ marginBottom: 20, marginTop: 5 }}
            />
            {/* CASETA INPUT PAROLA VECHE */}
            <CasetaTextParola
              label={'Parola nouă'}
              value={parolaNoua}
              setValue={setParolaNoua}
              showPassword={showParolaNoua}
              setShowPassword={setShowParolaNoua}
            />
            <ButonSubmit
              label={'Confirmare'}
              fieldStyle={{ position: 'absolute', bottom: 20 }}
              onPress={() => {
                // se trimit datele catre server
                dispatch({
                  type: 'POST_SCHIMBARE_PAROLA_REQUEST',
                  payload: { token: auth.token, parola_veche: parolaVeche.trim(), parola_noua: parolaNoua.trim() },
                });

                // se reseteaza casetele text
                setParolaVeche('');
                setParolaNoua('');
                setShowParolaNoua(false);
                setShowParolaVeche(false);
              }}
              // butonul este dezactivat daca campurile nu sunt completate corect
              disabled={!(parolaRegex.test(parolaNoua) && parolaRegex.test(parolaVeche))}
            />
          </View>
        </View>

        {/* CONTAINER CU BUTOANELE PENTRU STERS CREDENTIUALELE TUYA SI EWELINK */}
        <View style={{ width: '49%', height: '100%' }}>
          <View style={themeStyles.formularEdiareSetari}>
            <TitluFormular label={'Eliminare servicii cloud'} />
            <View style={{ marginTop: 5, width: '100%' }}>
              {/* butoanele apar doar daca sunt setate astfel de credentiale in baza de date */}
              {dateCont.are_credentiale_tuya == 1 && (
                <ButonSubmit
                  label={'Ștergere credențiale Tuya Smart'}
                  fieldStyle={{ marginBottom: 30 }}
                  onPress={() => setModalStergereCredentialeTuyaVizibil(true)}
                />
              )}
              {dateCont.are_credentiale_ewelink == 1 && (
                <ButonSubmit label={'Ștergere credențiale eWeLink'} onPress={() => setModalStergereCredentialeEwelinkVizibil(true)} />
              )}
            </View>

            {/* MESAJ INFORMATIV CARE APARE DOAR ATUNCI CAND UTILIZATORUL NU ARE SETAT NICIUN SERVICIU DE CLOUD */}
            {dateCont.are_credentiale_ewelink == 0 && dateCont.are_credentiale_tuya == 0 && (
              <View style={{ width: '80%', alignSelf: 'center', alignItems: 'center' }}>
                <CloudOff sx={{ color: theme.text100, fontSize: 80 }} />
                <Text style={{ alignSelf: 'center', fontSize: 18, color: theme.text100, marginTop: 10, fontWeight: 'bold' }}>
                  Nu aveți conectat niciun serviciu de cloud!
                </Text>
              </View>
            )}
          </View>

          {/* SPATIU INTRE ELEMENTE */}
          <View style={{ width: '100%', height: '2%' }} />

          {/* FORMULAR PENTRU STERGERE CONT */}
          <View style={themeStyles.formularEdiareSetari}>
            <TitluFormular label={'Ștergere cont utilizator'} />

            {/* CASETA INPUT PAROLA ACTUALA */}
            <CasetaTextParola
              label={'Parola actuală'}
              value={parolaActuala}
              setValue={setParolaActuala}
              showPassword={showParolaActuala}
              setShowPassword={setShowParolaActuala}
              fieldStyle={{ marginBottom: 30, marginTop: 5 }}
            />
            {/* BUTON PENTRU STERGERE CONT */}
            <ButonSubmit
              label={'Ștergere Cont'}
              fieldStyle={{ position: 'absolute', bottom: 20 }}
              onPress={() => setModalStergereContVizibil(true)}
              // butonul este dezactivat daca campurile nu sunt completate corect
              disabled={!parolaRegex.test(parolaActuala)}
            />
          </View>
        </View>

        {/* MODAL CONFIRMARE STERGERE CONT */}
        <DialogAlerta
          vizibil={modalStergereContVizibil}
          setVizibil={setModalStergereContVizibil}
          titlu={'Ștergere cont'}
          text={'Sunteți sigur că doriți să ștergeți contul de utilizator? Toate datele dumneavoastră se vor pierde!'}
          buton={() => handleStergereCont()}
        />

        {/* MODAL CONFIRMARE STERGERE CREDENTIALE TUYA SMART */}
        <DialogAlerta
          vizibil={modalStergereCredentialeTuyaVizibil}
          setVizibil={setModalStergereCredentialeTuyaVizibil}
          titlu={'Ștergere credențiale Tuya Smart'}
          text={
            'Sunteți sigur că doriți să ștergeți credențailele Tuya Smart? În urma acestei acțiuni toate dispozitivele care utilizează acest serviciu cloud vor fi șterse. Continuați?'
          }
          buton={() => handleStergereCredentiale(TUYA)}
        />

        {/* MODAL CONFIRMARE STERGERE CREDENTIALE EWELINK */}
        <DialogAlerta
          vizibil={modalStergereCredentialeEwelinkVizibil}
          setVizibil={setModalStergereCredentialeEwelinkVizibil}
          titlu={'Ștergere credențiale eWeLink'}
          text={
            'Sunteți sigur că doriți să ștergeți credențailele eWeLink? În urma acestei acțiuni toate dispozitivele care utilizează acest serviciu cloud vor fi șterse. Continuați?'
          }
          buton={() => handleStergereCredentiale(EWELINK)}
        />
      </Animated.View>
    )
  );
}

// FORMULAR PENTRU INTRODUCERE CREDENTIALE TUYA SMART
export function FormularTuya({ fadeAnimation }) {
  // state-uri pentru credentialele Tuya
  const [accessKey, setAccessKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [showSecretKey, setShowSecretKey] = useState('');
  const indicator = useSelector((state) => state.postCredentiale.loading);
  const auth = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  return (
    <Animated.View style={[themeStyles.formularCredentiale, { opacity: fadeAnimation }]}>
      <TitluFormular label={'Conectare cont Tuya Smart'} />
      <View style={{ height: '80%', width: '50%', justifyContent: 'center' }}>
        {/* CASETA ACCES KEY TUYA SMART */}
        <CasetaTextAcessKey label={'Access Key Tuya Smart'} value={accessKey} setValue={setAccessKey} fieldStyle={{ marginBottom: '10px' }} />
        {/* CASETA SECRET KEY TUYA */}
        <CasetaTextSecretKey
          label={'Secret Key Tuya Smart'}
          value={secretKey}
          setValue={setSecretKey}
          showSecretKey={showSecretKey}
          setShowSecretKey={setShowSecretKey}
          fieldStyle={{ marginBottom: '70px' }}
        />
        {/* BUTON PENTRU SALVARE DATE*/}
        <ButonSubmit
          label={'Salvare Date'}
          disabled={!(accessKey.length === LUNGIME_ACCESS_KEY_TUYA && secretKey.length === LUNGIME_SECRET_KEY_TUYA)}
          onPress={() => {
            // se trimit datele catre server
            dispatch({
              type: 'POST_CREDENTIALE_REQUEST',
              payload: {
                token: auth.token,
                user: accessKey.trim(),
                parola: secretKey.trim(),
                serviciu: TUYA,
              },
            });

            // campurile revin la starea initiala
            setAccessKey('');
            setSecretKey('');
            setShowSecretKey(false);
          }}
        />
      </View>
      <IndicatorStare vizibil={indicator} />
    </Animated.View>
  );
}

// FORMULAR PENTRU INTRODUCERE CREDENTIALE EWELINK
export function FormularEwelink({ fadeAnimation }) {
  // state-uri pentru credentialele eWeLink
  const [emailEwelink, setEmailEwelink] = useState('');
  const [parolaEwelink, setParolaEwelink] = useState('');
  const [showParolaEwelink, setShowParolaEwelink] = useState('');
  const indicator = useSelector((state) => state.postCredentiale.loading);
  const auth = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  return (
    <Animated.View style={[themeStyles.formularCredentiale, { opacity: fadeAnimation }]}>
      <TitluFormular label={'Conectare cont eWeLink'} />
      <View style={{ height: '80%', width: '50%', justifyContent: 'center' }}>
        {/* CASETA IMPUT EMAIL EWELINK*/}
        <CasetaTextEmail label={'Email cont eWeLink'} value={emailEwelink} setValue={setEmailEwelink} fieldStyle={{ marginBottom: '10px' }} />
        {/* CASETA INPUT PAROLA EWELINK */}
        <CasetaTextParola
          label={'Parola cont eWeLink'}
          value={parolaEwelink}
          setValue={setParolaEwelink}
          showPassword={showParolaEwelink}
          setShowPassword={setShowParolaEwelink}
          fieldStyle={{ marginBottom: '70px' }}
        />
        {/* BUTON PENTRU SALVARE DATE*/}
        <ButonSubmit
          label={'Salvare Date'}
          disabled={!(emailRegex.test(emailEwelink) && parolaEwelink !== '')}
          onPress={() => {
            // se trimit datele catre server
            dispatch({
              type: 'POST_CREDENTIALE_REQUEST',
              payload: {
                token: auth.token,
                user: emailEwelink.trim(),
                parola: parolaEwelink.trim(),
                serviciu: EWELINK,
              },
            });

            // campurile revin la starea initiala
            setEmailEwelink('');
            setParolaEwelink('');
            setShowParolaEwelink(false);
          }}
        />
      </View>
      <IndicatorStare vizibil={indicator} />
    </Animated.View>
  );
}
