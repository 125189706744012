import { createContext, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Crează un context pentru navigare
const NavigationContext = createContext(null);

// Componenta care oferă contextul de navigare întregii aplicații
export function NavigationProvider({ children }) {
  // Folosește hook-ul useNavigate pentru a obține funcția de navigare
  const navigate = useNavigate();

  // Folosește useRef pentru a păstra o referință constantă la funcția de navigare
  const navigationRef = useRef(navigate);

  useEffect(() => {
    // Setează funcția de navigare
    setNavigate(navigationRef.current);
  }, []);

  // Oferă funcția de navigare tuturor componentelor care consumă acest context
  return <NavigationContext.Provider value={navigationRef.current}>{children}</NavigationContext.Provider>;
}

// Hook personalizat pentru a accesa funcția de navigare din context
export function useNavigation() {
  // Folosește useContext pentru a accesa valoarea din NavigationContext
  return useContext(NavigationContext);
}

// Declara o variabilă globală pentru a stoca funcția de navigare
let navigate;

// Funcție pentru a seta funcția de navigare
export function setNavigate(navFunction) {
  // Stochează funcția de navigare primită în variabila globală
  navigate = navFunction;
}

// Funcție pentru a naviga către o anumită cale
export function navigateTo(path) {
  console.log('Apelare navigateTo catre ' + path);
  // Verifică dacă funcția de navigare a fost setată
  if (navigate) {
    // Apelează funcția de navigare cu calea specificată
    navigate(path);
  } else {
    console.error('navigate function is not set');
  }
}
