import { Animated } from 'react-native';

// Funcție pentru animarea fade-in
export const fadeInAnimation = (animatedValue, duration = 2000, delay = 0) => {
  return Animated.timing(animatedValue, {
    toValue: 1,
    duration: duration,
    delay: delay,
    useNativeDriver: true,
  });
};

// Funcție pentru animarea fade-out
export const fadeOutAnimation = (animatedValue, duration = 1000, delay = 0) => {
  return Animated.timing(animatedValue, {
    toValue: 0,
    duration: duration,
    delay: delay,
    useNativeDriver: true,
  });
};
