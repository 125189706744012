export const API_URL = 'https://iot-homes.com/';
//export const API_URL = 'http://192.168.1.231:3030/';
// Funcție pentru cererea HTTP folosind fetch
export async function fetchApi(request, endpoint) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const raw = JSON.stringify(request);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(API_URL + endpoint, requestOptions);

  // Returnăm rezultatul răspunsului
  return response.json();
}
