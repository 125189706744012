import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Text, useWindowDimensions, ScrollView, TouchableOpacity, FlatList, Image, Animated } from 'react-native';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { CloseRounded, DevicesOtherRounded, ListRounded } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { ButonReutilizabil } from '../components/butoane';
import { maxWidth, theme, themeStyles } from '../constants/themeColors1';
import { CardDevice } from '../components/cardDevice';
import { IndicatorStare } from '../components/IndicatorStare';
import { fadeInAnimation } from '../constants/animations';
import { isDesktop } from 'react-device-detect';
const EWELINK = 'eWeLink';
const TUYA = 'Tuya Smart';

const DashboardPage = () => {
  const { height, width } = useWindowDimensions();
  const [vectorDispozitive, setVectorDispozitive] = useState(null);
  const [dragEnabled, setDragEnabled] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.user);
  const dateCont = useSelector((state) => state.dateCont.dateContUtilizator);
  const indicator = useSelector((state) => state.comandaDispozitiv.loading);
  const stateDispozitive = useSelector((state) => state.dateDispozitive); // se preiua dispozitivele din store
  const [grupSelectat, setGrupSelectat] = useState(null); // se utilizeaza la lista cu grupuri utilizate de client

  // controleaza animatia la lista grupuri si butonul anulare filtare
  const fadeAnimListaGrupuri = useRef(new Animated.Value(0)).current;
  // controleaza animatie la aparitia cardurilor si la butonul ordonare dispozitive
  const fadeAnimCarduri = useRef(new Animated.Value(0)).current;
  // controleaza animatia la aparitia chenarului cu cardurile
  const fadeAnimChenarCarduri = useRef(new Animated.Value(0)).current;

  // La deschiderea paginii se activează animația de fade in
  useEffect(() => {
    stateDispozitive.dispozitive &&
      Animated.parallel([
        fadeInAnimation(fadeAnimListaGrupuri, 500, 400),
        fadeInAnimation(fadeAnimChenarCarduri, 200, 200),
        fadeInAnimation(fadeAnimCarduri, 1000, 500),
      ]).start();
  }, [stateDispozitive]);

  // FUNCTIE PENTRU PRELUAREA DISPOZITIVELOR
  const preluareDispozitive = () => {
    // Se trimit datele către server
    if (!dragEnabled) {
      dispatch({
        type: 'GET_DISPOZITIVE_REQUEST',
        payload: {
          token: auth.token,
          denumire_grup: grupSelectat,
          id_serviciu_cloud: null,
        },
      });
    }
  };

  const [contorPreluareDispozitive, setContorPreluareDispozitive] = useState(0);
  useEffect(() => {
    if (auth && dateCont) {
      // Se concatenează vectorii cu dispozitive Tuya și eWeLink, tratând vectorii null
      if (!dragEnabled) {
        let vector = stateDispozitive.dispozitive ? stateDispozitive.dispozitive : [];
        setVectorDispozitive(vector);
      }

      // Se apelează pentru prima dată dispozitivele la accesarea paginii
      preluareDispozitive();
      // Se preiau datele o dată la 2 secunde
      const interval = setInterval(() => {
        preluareDispozitive();
        setContorPreluareDispozitive((prev) => prev + 1);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [contorPreluareDispozitive, auth, dateCont]);

  // rander element pentru lista cu grupurile utilizate
  const randerListaGrupuri = ({ item }) => {
    return (
      <TouchableOpacity
        style={[themeStyles.butonGrup, { backgroundColor: grupSelectat === item.denumire_grup ? theme.primary200 : theme.accent100 }]}
        onPress={() => setGrupSelectat(item.denumire_grup)}
      >
        <Text style={themeStyles.textButonGrup}>{item.denumire_grup}</Text>
      </TouchableOpacity>
    );
  };

  const handleLayoutChange = (newLayout) => {
    const newItems = newLayout.map((layoutItem) => {
      const item = vectorDispozitive.find((i) => i.nr_ordine.toString() === layoutItem.i);
      return {
        ...item,
        layout: layoutItem,
      };
    });
    setVectorDispozitive(newItems);
  };

  // FUNCTIA CARE REORGANIZEAZA CARDURILE PE ECRAN SI IN BAZA DE DATE
  const handleDragStop = (layout) => {
    // Sortează și mapează noile ordini
    const updatedOrder = layout
      .sort((a, b) => a.y - b.y || a.x - b.x) // Sortează după y și apoi după x pentru a reflecta ordinea vizuală
      .map((item, index) => {
        const originalItem = vectorDispozitive.find((i) => i.nr_ordine.toString() === item.i);
        return {
          id: originalItem.id, // ID-ul dispozitivului
          nr_ordine: index + 1, // Noul nr_ordine bazat pe poziția curentă în layout
        };
      });

    dispatch({
      type: 'REORDONARE_REQUEST',
      payload: {
        token: auth.token,
        vector_ordine: updatedOrder,
      },
    });
  };

  return (
    <View style={[themeStyles.fundalPaginaSetari, { height: height - height / 15 }]}>
      {stateDispozitive.message && stateDispozitive.dispozitive !== null ? (
        <View style={{ width: '100%', height: '100%' }}>
          {/* // LISTA CU GRUPURILE DE DISPOZITIVE UTILIZATE */}

          <View style={styles.containerListaGrupuri}>
            {/* BUTON ACTIVARE DRAG AND DROP - apare doar daca nu exista niciun grup selectat si daca utilizatorul este conecta pe desktop PC */}
            {grupSelectat === null && isDesktop && (
              <ButonReutilizabil
                label={dragEnabled ? 'Salvare ordine' : 'Ordonare dispozitive'}
                icon={<ListRounded sx={{ color: theme.text100, alignSelf: 'center', marginLeft: 1 }} />}
                selected={dragEnabled}
                culoareButonSelectat={theme.primary100}
                culoareTextSelectat={theme.text100}
                opacity={fadeAnimCarduri}
                onPress={() => {
                  setDragEnabled(!dragEnabled);
                }}
              />
            )}
            {/* BUTON ANULARE FILTRARE  - apare doar anunci cand drag and drop este dezactivat*/}
            {!dragEnabled && (
              <ButonReutilizabil
                label={'Anulare filtrare'}
                icon={<CloseRounded sx={{ color: grupSelectat ? 'red' : theme.bg200, alignSelf: 'center', marginLeft: 1 }} />}
                selected={grupSelectat}
                onPress={() => setGrupSelectat(null)}
                opacity={fadeAnimListaGrupuri}
              />
            )}
            {/* LISTA CU GRUPURI UTILIZATE - apare doar atunci cand functia drag and drop este dezactivata */}
            {!dragEnabled && (
              <Animated.FlatList
                style={{ margin: 0, opacity: fadeAnimListaGrupuri }}
                showsHorizontalScrollIndicator={false}
                data={stateDispozitive.grupuri_utilizate}
                renderItem={randerListaGrupuri}
                keyExtractor={(item) => item.nr_ordine.toString()}
                horizontal
              />
            )}
          </View>

          <Animated.ScrollView style={[styles.scrollview, { opacity: fadeAnimChenarCarduri }]} showsVerticalScrollIndicator={false}>
            {vectorDispozitive && (
              <GridLayout
                className="layout"
                layout={vectorDispozitive.map((item, index) => ({
                  i: item.nr_ordine.toString(),
                  x: (index % 4) * 3,
                  y: Math.floor(index / 4) * 2,
                  w: item.dimensiuni.w, // Lățimea variabilă specificată pentru fiecare item
                  h: item.dimensiuni.h, // Înălțimea variabilă specificată pentru fiecare item
                  isResizable: false, // Dezactivare redimensionare
                  isDraggable: dragEnabled, // Setăm dacă elementele sunt draggable sau nu
                }))}
                cols={12}
                rowHeight={50}
                width={width < maxWidth ? width : maxWidth}
                margin={[20, 20]}
                onLayoutChange={handleLayoutChange}
                onDragStop={handleDragStop} // Callback care se declanșează la finalul unei acțiuni de drag
              >
                {vectorDispozitive.map((item) =>
                  !dragEnabled ? (
                    // ELEMENTUL DE COMANDA SI CONTROL CARE APARE ATUNCI CAND DRAG AND DROP ESTE DEZACTIVAT
                    <CardDevice
                      item={item}
                      key={item.nr_ordine}
                      style={themeStyles.cardDispozitiv}
                      auth={auth}
                      dateCont={dateCont}
                      opacity={fadeAnimCarduri}
                    />
                  ) : (
                    // ELEMENTUL CARE APARE ATUNCI CAND SE MUTA ELEMENTELE PRIN DRAG AND DROP
                    <View key={item.nr_ordine} style={themeStyles.cardDispozitiv}>
                      <Image source={require(`../assets/${item.tip_dispozitiv}.png`)} style={{ width: 35, height: 35 }} />
                      <Text style={{ color: theme.text100, fontSize: 16, marginTop: 5 }}>{item.nume_dispozitiv_db}</Text>
                      <Text style={{ color: theme.text100, fontStyle: 'italic', fontSize: 12, marginTop: 5 }}>
                        {item.denumire_grup} - {item.nume_dispozitiv_cloud}
                      </Text>
                    </View>
                  )
                )}
              </GridLayout>
            )}
          </Animated.ScrollView>
        </View>
      ) : (
        <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <DevicesOtherRounded sx={{ color: theme.text100, fontSize: 100 }} />
          <Text style={styles.stilText}>Nu există dispozitive adăugate!</Text>
          <Text style={styles.stilText}>Accesați meniul Setări pentru a adăuga dispozitive în contul dvs. </Text>
        </View>
      )}

      <IndicatorStare vizibil={indicator} />
    </View>
  );
};

export default DashboardPage;

const styles = StyleSheet.create({
  containerListaGrupuri: {
    width: '100%',
    maxWidth: maxWidth,
    height: 50,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },

  scrollview: {
    width: '100%',
    maxWidth: maxWidth,
    height: '100%',
    alignSelf: 'center',
    margin: 10,
    backgroundColor: theme.bg200,
    alignContent: 'center',
    borderRadius: 10,
  },
  stilText: {
    alignSelf: 'center',
    fontSize: 18,
    color: theme.text100,
    marginTop: 10,
    fontWeight: 'bold',
  },
});
