import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';
import { navigateTo } from '../context/NavigationContext';

// Worker saga
function* login(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'autentificare');
    if (response.error) {
      yield put({ type: 'LOGIN_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      // se salveaza datele in local storage
      localStorage.setItem('userData', JSON.stringify(response.response));
      yield put({ type: 'LOGIN_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });

      // se navigeaza catre pagina dashboard
      navigateTo('/dashboard');
    }
  } catch (error) {
    yield put({ type: 'LOGIN_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchLogin() {
  yield takeLatest('LOGIN_REQUEST', login);
}

export default watchLogin;
