import { StyleSheet } from 'react-native';

export const defaultTheme = {
  id: 1,
  denumire: 'default 1',
  culori: {
    primary100: '#d4eaf7',
    primary200: '#b6ccd8',
    primary300: '#3b3c3d',
    accent100: '#71c4ef',
    accent200: '#00668c',
    text100: '#1d1c1c',
    text200: '#313d44',
    bg100: '#fffefb',
    bg200: '#f5f4f1',
    bg300: '#cccbc8',
  },
};

export const maxWidth = 1500;
// se preia tema din local storage daca aceasta exista
const storedTheme = localStorage.getItem('theme');
const culori = JSON.parse(storedTheme);
export const theme = storedTheme ? culori.culori : defaultTheme.culori;

export const themeStyles = StyleSheet.create({
  fundal: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.bg100,
    justifyContent: 'center',
    alignItems: 'center',
  },

  continerPaginaLogin: {
    width: '100%',
    maxWidth: maxWidth,
    height: '100%',
    flexDirection: 'row',
  },

  containerStanga: {
    width: '50%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },

  iconLogin: {
    width: 300,
    height: 300,
  },

  titluLogin: {
    fontSize: 40,
    marginTop: 20,
    color: theme.text100,
  },

  fundalFormularLogin: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.bg300,
    width: '100%',
    height: '98%',
    borderRadius: 10,
  },

  fundalPaginaSetari: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.bg100,
    width: '100%',
    // maxWidth: maxWidth,
    flexDirection: 'row',
  },

  butonNavBar: {
    backgroundColor: theme.primary100,
    margin: 0.5,
  },

  textButonNavbar: {
    color: theme.text100,
    margin: 5,
  },

  iconButonNavBar: {
    color: theme.primary300,
    width: 30,
    height: 30,
    alignSelf: 'center',
  },

  baraLaterala: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.bg300,
  },

  formularSetariCont: {
    width: '100%',
    maxWidth: maxWidth,
    height: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },

  formularEdiareSetari: {
    width: '100%',
    height: '49%',
    backgroundColor: theme.bg300,
    borderRadius: 10,
  },

  formularCredentiale: {
    width: '100%',
    height: '100%',
    maxWidth: maxWidth,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.bg300,
    borderRadius: 10,
  },

  formularDispozitive: {
    width: '100%',
    height: '100%',
    maxWidth: maxWidth,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  butonGrup: {
    height: 40,
    justifyContent: 'center',
    borderRadius: 5,
    margin: 5,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 4 }, // Deplasarea umbrei
    shadowOpacity: 0.9, // Opacitatea umbrei
    shadowRadius: 10, // Raza de blurare a umbrei
    elevation: 5, // Pentru Android
  },

  textButonGrup: {
    fontSize: 16,
    alignSelf: 'center',
    marginLeft: 10,
    marginRight: 10,
    color: theme.text100,
  },

  cardDispozitiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.accent100,
    borderRadius: 5,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 4 }, // Deplasarea umbrei
    shadowOpacity: 0.9, // Opacitatea umbrei
    shadowRadius: 10, // Raza de blurare a umbrei
    elevation: 5, // Pentru Android
  },
});

// const theme = {
//   primary100: '#0085ff',
//   primary200: '#69b4ff',
//   primary300: '#e0ffff',
//   accent100: '#006fff',
//   accent200: '#e1ffff',
//   text100: '#FFFFFF',
//   text200: '#9e9e9e',
//   bg100: '#1E1E1E',
//   bg200: '#2d2d2d',
//   bg300: '#454545',
// };

// export const theme = {
//   primary100: '#8FBF9F',
//   primary200: '#68a67d',
//   primary300: '#24613b',
//   accent100: '#F18F01',
//   accent200: '#833500',
//   text100: '#353535',
//   text200: '#5f5f5f',
//   bg100: '#F5ECD7',
//   bg200: '#ebe2cd',
//   bg300: '#c2baa6',
// };

// export const theme = {
//   primary100: '#3F51B5',
//   primary200: '#757de8',
//   primary300: '#dedeff',
//   accent100: '#2196F3',
//   accent200: '#003f8f',
//   text100: '#333333',
//   text200: '#5c5c5c',
//   bg100: '#FFFFFF',
//   bg200: '#f5f5f5',
//   bg300: '#cccccc',
// };

// export const theme = {
//   primary100: '#5DA399',
//   primary200: '#40867d',
//   primary300: '#004840',
//   accent100: '#FF6B6B',
//   accent200: '#8f001a',
//   text100: '#333333',
//   text200: '#5c5c5c',
//   bg100: '#E0E7E9',
//   bg200: '#d6dddf',
//   bg300: '#aeb5b7',
// };
