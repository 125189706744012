import { View, Text, Animated, FlatList, TouchableOpacity, StyleSheet, useWindowDimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import { TitluFormular } from './caseteText';

import { theme, themeStyles } from '../constants/themeColors1';
import { API_URL } from '../sagas/fetchConfig';

export function FormularEditareTemeCuloare({ fadeAnimation, auth }) {
  const { height, width } = useWindowDimensions();
  const storedTheme = localStorage.getItem('theme');
  const culoriSalvate = JSON.parse(storedTheme);
  const [temeCuloare, setTemeCuloare] = useState(null);

  // FUNCTIE CARE PREIA TEMELE DE CULOARE DIN BAZA DE DATE
  const preluareTeme = () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      token: auth.token,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch(API_URL + 'preluare_teme', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTemeCuloare(result.response);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    preluareTeme();
  }, []);

  // FUNCTIA CARE SE OCUPA CU SALVAREA TEMEI IN LOCAL STORAGE
  const handleSelectareTema = (data) => {
    localStorage.setItem('theme', JSON.stringify(data));
    window.location.reload();
  };

  // COMPONENTA REUTILIZABILA PENTRU AFISARE COLORII
  const ViewCuloare = ({ culoare }) => {
    return <View style={{ width: 30, height: 30, borderRadius: 100, backgroundColor: culoare, marginRight: 5 }} />;
  };

  // rander element pentru lista cu culori
  const randerListaCulori = ({ item }) => {
    return (
      <TouchableOpacity
        style={[styles.cardDispozitiv, { backgroundColor: culoriSalvate.id === item.id ? theme.primary200 : theme.accent100 }]}
        onPress={() => handleSelectareTema(item)}
      >
        {/* View cu denumirea dispozitivului */}
        <View style={{ width: '50%' }}>
          <Text style={{ color: theme.text100, fontSize: 18, fontWeight: '600', marginLeft: 20 }}>{item.denumire} </Text>
        </View>

        {/* View cu culorile temei */}
        <View style={{ width: '47%', justifyContent: 'flex-end', flexDirection: 'row' }}>
          <ViewCuloare culoare={item.culori.primary100} />
          <ViewCuloare culoare={item.culori.primary200} />
          <ViewCuloare culoare={item.culori.primary300} />
          <ViewCuloare culoare={item.culori.accent100} />
          <ViewCuloare culoare={item.culori.accent200} />
          <ViewCuloare culoare={item.culori.text100} />
          <ViewCuloare culoare={item.culori.text200} />
          <ViewCuloare culoare={item.culori.bg100} />
          <ViewCuloare culoare={item.culori.bg200} />
          <ViewCuloare culoare={item.culori.bg300} />
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <Animated.View style={[themeStyles.formularCredentiale, { opacity: fadeAnimation }]}>
      <TitluFormular label={'Editare temă culoare'} />

      {temeCuloare && (
        <View style={[styles.containerListaDispozitive, { height: height / 1.35 }]}>
          <FlatList
            showsVerticalScrollIndicator={false}
            style={{ padding: 15 }}
            data={temeCuloare}
            renderItem={randerListaCulori}
            keyExtractor={(item) => item.id.toString()}
          />
        </View>
      )}
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  stilText: {
    alignSelf: 'center',
    fontSize: 18,
    color: theme.text100,
    marginTop: 10,
    fontWeight: 'bold',
  },

  container: {
    width: '97%',
    height: '85%',
    alignSelf: 'center',
  },

  containerListaGrupuri: {
    width: '90%',
    height: 60,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },

  containerListaDispozitive: {
    width: '95%',
    alignSelf: 'center',
    height: '80%',
  },

  cardDispozitiv: {
    width: '100%',
    height: 50,
    marginBottom: 10,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 4 }, // Deplasarea umbrei
    shadowOpacity: 0.9, // Opacitatea umbrei
    shadowRadius: 10, // Raza de blurare a umbrei
    elevation: 5, // Pentru Android
  },

  containerButoane: {
    width: '90%',
    alignSelf: 'center',
    position: 'absolute',
    bottom: 20,
  },
});
