import { View, Text, StyleSheet, Animated, FlatList, TouchableOpacity, Image, useWindowDimensions } from 'react-native';
import React, { useState, useEffect } from 'react';
import { ButonSubmit, ButonSubmitMic, CasetaTextNumeDispozitiv, CasetaTextNumeGrup, TitluFormular } from './caseteText';
import { useDispatch, useSelector } from 'react-redux';
import { ClearRounded, CloseRounded, DevicesOtherRounded, WifiOffRounded, WifiRounded } from '@mui/icons-material';
import { IndicatorStare } from './IndicatorStare';
import DialogAlerta from './DialogAlerta';
import { theme, themeStyles } from '../constants/themeColors1';
import { ButonReutilizabil } from './butoane';

const EWELINK = 'eWeLink';
const TUYA = 'Tuya Smart';

// FORMULARUL IN CARE SUNT AFISATE DISPOZITIVELE, DE AICI SE POAT EDITA SAU ADAUGA NOI DISPOZITIVE
export function FormularDispozitive({ fadeAnimation, dateCont, auth, numeServiciuCloud, idServiciuCloud }) {
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const indicator = useSelector((state) => state.adaugareDispozitiv.loading);
  const [numeDispozitiv, setNumeDispozitiv] = useState('');
  const [idDispozitiv, setIdDispozitiv] = useState('');
  const [denumireGrup, setDenumireGrup] = useState('');
  const [tipDispozitiv, setTipDispozitiv] = useState('');
  const [grupSelectat, setGrupSelectat] = useState(null); // se utilizeaza la lista cu grupuri utilizate de client
  const [idDispozitivSelectat, setIdDispozitivSelectat] = useState(null);
  const stateDispozitive = useSelector((state) => state.dateDispozitive); // se preiua dispozitivele din store
  const [modalStergereDispozitivVizibil, setModalStergereDispozitivVizibil] = useState(false);
  const [modalVizibilStergeGrup, setModalVizibilStergereGrup] = useState(false);
  const [vizibilListaGrupuri, setVizibilListaGrupuri] = useState(false);
  const [numeGrupSters, setNumeGrupSters] = useState(''); // se retine numele grupului care urmeaza sa fie sters

  const [contorPreluareDispozitive, setContorPreluareDispozitive] = useState(0);
  useEffect(() => {
    const preluareDispozitive = () => {
      // se trimit datele catre server
      dispatch({
        type: 'GET_DISPOZITIVE_REQUEST',
        payload: {
          token: auth.token,
          denumire_grup: grupSelectat,
          id_serviciu_cloud: idServiciuCloud,
        },
      });
    };

    // se apeleaza pentru prima data dispozitivele la accesarea paginii
    preluareDispozitive();
    // se preiau datele o data la 2 secunde
    const interval = setInterval(() => {
      preluareDispozitive();
      setContorPreluareDispozitive(contorPreluareDispozitive + 1);
    }, 2000);

    return () => clearInterval(interval);
  }, [contorPreluareDispozitive]);

  // functie care gestioneaza selectarea/deselectarea dispozitivului
  const handleSelectareDispozitiv = (data) => {
    if (data) {
      // daca data este diferit de null atunci se completeaza datele in formular
      setIdDispozitivSelectat(data.id);
      // se completeaza campurile formularului din stanga cu aceste date
      setNumeDispozitiv(data.nume_dispozitiv_db);
      setIdDispozitiv(data.id_cloud_dispozitiv);
      setDenumireGrup(data.denumire_grup);
      setTipDispozitiv(data.tip_dispozitiv);
    } else {
      // in caz contrar formularul se goleste
      setIdDispozitivSelectat(null);
      setNumeDispozitiv('');
      setDenumireGrup('');
      setIdDispozitiv('');
      setTipDispozitiv('');
    }
  };

  // rander element pentru lista cu grupurile utilizate
  const randerListaGrupuri = ({ item }) => {
    return (
      <TouchableOpacity
        style={[themeStyles.butonGrup, { backgroundColor: grupSelectat === item.denumire_grup ? theme.primary200 : theme.accent100 }]}
        onPress={() => setGrupSelectat(item.denumire_grup)}
      >
        <Text style={themeStyles.textButonGrup}>{item.denumire_grup}</Text>
      </TouchableOpacity>
    );
  };

  // rander element pentru lista cu dispozitive
  const randerListaDispozitive = ({ item }) => {
    return (
      <TouchableOpacity
        style={[styles.cardDispozitiv, { backgroundColor: idDispozitivSelectat === item.id ? theme.primary200 : theme.accent100 }]}
        onPress={() => handleSelectareDispozitiv(item)}
      >
        {/* View cu iconul potrivit dispozitivului */}
        <View style={{ width: '5%' }}>
          <Image source={require(`../assets/${item.tip_dispozitiv}.png`)} style={{ width: '35px', height: '35px', marginLeft: 10 }} />
        </View>
        {/* View cu denumirea dispozitivului */}
        <View style={{ width: '85%' }}>
          <Text style={{ color: theme.text100, fontSize: 18, fontWeight: '600', marginLeft: 20 }}>
            {item.nume_dispozitiv_db}{' '}
            <Text style={{ fontStyle: 'italic' }}>
              {' '}
              ({item.denumire_grup} - {item.nume_dispozitiv_cloud}){' '}
            </Text>
          </Text>
        </View>

        {/* View cu starea dispozitivului */}
        <View style={{ width: '10%', justifyContent: 'flex-end' }}>
          {item.online ? (
            <WifiRounded sx={{ color: theme.text100, alignSelf: 'center', marginLeft: 1 }} />
          ) : (
            <WifiOffRounded sx={{ color: theme.text100, alignSelf: 'center', marginLeft: 1 }} />
          )}
        </View>
      </TouchableOpacity>
    );
  };

  // rander element pentru lista cu grupuri dispozitive
  const randerListaToateGrupurile = ({ item }) => {
    return (
      <View style={[styles.cardDispozitiv, { backgroundColor: theme.accent100 }]}>
        {/* View cu icon grup*/}
        <View style={{ width: '5%' }}>
          <Image source={require(`../assets/smart_home.png`)} style={{ width: '35px', height: '35px', marginLeft: 10 }} />
        </View>

        {/* View cu denumirea grupului */}
        <View style={{ width: '45%' }}>
          <Text style={{ color: theme.text100, fontSize: 18, fontWeight: '600', marginLeft: 20 }}>{item.nume}</Text>
        </View>

        {/* View cu numarul de dispozitive din grup */}
        <View style={{ width: '40%' }}>
          <Text style={{ color: theme.text100, fontSize: 16, fontWeight: '400', marginRight: 20, textAlign: 'right' }}>
            Nr. dispozitive: {item.nr_dispozitive}
          </Text>
        </View>

        {/* Buton stergere */}
        {item.nr_dispozitive === 0 && (
          <View style={{ width: '10%' }}>
            <TouchableOpacity
              onPress={() => {
                setNumeGrupSters(item.nume);
                setModalVizibilStergereGrup(true);
              }}
            >
              <ClearRounded sx={{ color: theme.text100, alignSelf: 'center', marginLeft: 1 }} />
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  const [butonSubmitEnable, setButonSubmitEnabled] = useState(false);
  useEffect(() => {
    if (numeDispozitiv === '' || idDispozitiv === '' || denumireGrup === '' || tipDispozitiv === '') {
      // daca oricare dintre campurile de mai sus nu sunt completate atunci nu se permite trimiterea datelor
      setButonSubmitEnabled(false);
    } else {
      // daca toate campurile sunt completate atunci se permite trimiderea datelor catre server
      setButonSubmitEnabled(true);
    }
  }, [numeDispozitiv, idDispozitiv, denumireGrup, tipDispozitiv]);

  // se filtreaza tipurile de dispozitive in functie de idServiciuCloud
  const dispozitiveSelectate = dateCont.tipuri_dispzitive.filter((dispozitiv) => dispozitiv.id_serviciu_cloud === idServiciuCloud);

  return (
    <Animated.View style={[themeStyles.formularDispozitive, { opacity: fadeAnimation }]}>
      {/* COLOANA CU FORMULARUL PENTRU ADAUGAREA UNUI NOU DISPOZITIV */}
      <View style={{ width: '34%', height: '100%', backgroundColor: theme.bg300, borderRadius: 10 }}>
        <TitluFormular label={idDispozitivSelectat ? 'Editare dispozitiv ' + numeServiciuCloud : 'Adăugare dispozitive ' + numeServiciuCloud} />
        <CasetaTextNumeDispozitiv label={'Numele dispozitivului dvs.'} value={numeDispozitiv} setValue={setNumeDispozitiv} />
        {idDispozitivSelectat === null && (
          // Nu apare atunci cand se editeaza dispozitivul
          <CasetaTextNumeDispozitiv label={'ID-ul dispozitivului dvs.'} value={idDispozitiv} setValue={setIdDispozitiv} />
        )}
        <CasetaTextNumeGrup label="Nume Grup" value={denumireGrup} setValue={setDenumireGrup} grupuriDispozitive={dateCont.grupuri_dispozitive} />
        <CasetaTextNumeGrup
          disabled={true} // nu i se permite clientului sa completeze cu alte nume de dispozitive
          label="Tip dispozitiv"
          value={tipDispozitiv}
          setValue={setTipDispozitiv}
          grupuriDispozitive={dispozitiveSelectate}
        />
        {idDispozitivSelectat === null && (
          <View style={styles.containerButoane}>
            {/* BUTON PENTRU ADAUGARE DISPOZITIV */}
            <ButonSubmit
              label={'Adăugați dispozitiv ' + numeServiciuCloud}
              fieldStyle={{ width: '100%', bottom: 20 }}
              disabled={!butonSubmitEnable}
              onPress={() => {
                // se trimit datele catre server
                dispatch({
                  type: 'ADAUGARE_DISPOZITIV_REQUEST',
                  payload: {
                    token: auth.token,
                    id_cloud_dispozitiv: idDispozitiv.trim(),
                    nume_dispozitiv: numeDispozitiv.trim(),
                    denumire_grup: denumireGrup.trim(),
                    tip_dispozitiv: tipDispozitiv.trim(),
                    id_serviciu_cloud: idServiciuCloud,
                  },
                });

                // campurile revin la starea initiala
                setNumeDispozitiv('');
                setIdDispozitiv('');
                setDenumireGrup('');
                setTipDispozitiv('');
              }}
            />

            {/* BUTON PENTRU EDITARE LISTA GRUPURI */}
            <ButonSubmit
              label={vizibilListaGrupuri ? 'Închidere listă grupuri dispozitive' : 'Gestionare grupuri dispozitive'}
              fieldStyle={{ width: '100%', bottom: 20 }}
              onPress={() => setVizibilListaGrupuri(!vizibilListaGrupuri)}
            />
          </View>
        )}

        {idDispozitivSelectat && (
          <View style={styles.containerButoane}>
            <View style={{ width: '100%', marginBottom: 20, flexDirection: 'row', justifyContent: 'space-between' }}>
              {/* BUTON ACTIONARE EDITARE DATE */}
              <ButonSubmitMic
                label={'Editare dispozitiv'}
                disabled={!butonSubmitEnable}
                onPress={() => {
                  // se trimit datele catre server
                  dispatch({
                    type: 'POST_EDITARE_DISPOZITIV_REQUEST',
                    payload: {
                      token: auth.token,
                      id_cloud_dispozitiv: idDispozitiv.trim(),
                      nume_dispozitiv: numeDispozitiv.trim(),
                      denumire_grup: denumireGrup.trim(),
                      tip_dispozitiv: tipDispozitiv.trim(),
                    },
                  });
                }}
              />
              {/* BUTON PENTRU DESELECTARE DISPOZITIV */}
              <ButonSubmitMic label={'Anulare'} onPress={() => handleSelectareDispozitiv(null)} />
            </View>

            {/* BUTON STERGERE DISPOZITIV */}
            <ButonSubmit
              label={'Ștergere dispozitiv ' + numeServiciuCloud}
              fieldStyle={{ width: '100%', bottom: 20 }}
              onPress={() => setModalStergereDispozitivVizibil(true)}
            />

            {/* BUTON PENTRU EDITARE LISTA GRUPURI */}
            <ButonSubmit
              label={vizibilListaGrupuri ? 'Închidere listă grupuri dispozitive' : 'Gestionare grupuri dispozitive'}
              fieldStyle={{ width: '100%', bottom: 20 }}
              onPress={() => setVizibilListaGrupuri(!vizibilListaGrupuri)}
            />
          </View>
        )}
      </View>

      {/* COLOANA CU LISTA DE DISPOZITIVE/GRUPURI DISPOZITIVE */}
      <View style={{ width: '65%', height: '100%', backgroundColor: theme.bg300, borderRadius: 10 }}>
        <TitluFormular
          label={
            vizibilListaGrupuri
              ? 'Lista grupuri dispozitive'
              : 'Administrare dispozitive ' + numeServiciuCloud + (grupSelectat ? ': ' + grupSelectat : ' ')
          }
        />

        {/* LISTA DE DISPOZITIVE NU APARE ATUNCI CAND LISTA CU GRUPURI ESTE VIZIBILA */}
        {stateDispozitive.message && stateDispozitive.dispozitive !== null && vizibilListaGrupuri === false && (
          // LISTA CU GRUPURILE DE DISPOZITIVE UTILIZATE
          <View style={styles.container}>
            <View style={styles.containerListaGrupuri}>
              <ButonReutilizabil
                label={'Anulare filtrare'}
                icon={<CloseRounded sx={{ color: grupSelectat ? theme.text100 : theme.bg200, alignSelf: 'center', marginLeft: 1 }} />}
                onPress={() => setGrupSelectat(null)}
                selected={grupSelectat}
              />

              <FlatList
                data={stateDispozitive.grupuri_utilizate}
                showsHorizontalScrollIndicator={false}
                renderItem={randerListaGrupuri}
                keyExtractor={(item) => item.nr_ordine.toString()}
                horizontal
              />
            </View>

            {/* LISTA CU DISPOZITIVE */}
            <View style={[styles.containerListaDispozitive, { height: height / 1.45 }]}>
              <FlatList
                style={{ padding: 15 }}
                showsVerticalScrollIndicator={false}
                data={stateDispozitive.dispozitive}
                renderItem={randerListaDispozitive}
                keyExtractor={(item) => item.nr_ordine.toString()}
              />
            </View>
          </View>
        )}

        {/* MESAJ CARE APARE PE ECRAN ATUNCI CAND NU EXISTA DISPOZITIVE ADAUGATE IN CONTUL CLIENTULUI SI CAND LISTA CU GRUPURI ESTE ASCUNSA */}
        {stateDispozitive.message && stateDispozitive.dispozitive === null && vizibilListaGrupuri === false && (
          <View style={{ width: '80%', alignSelf: 'center', alignItems: 'center', height: '80%', justifyContent: 'center' }}>
            <DevicesOtherRounded sx={{ color: theme.text100, fontSize: 100 }} />
            <Text style={styles.stilText}>Nu există dispozitive care să utilizeze serviciul {numeServiciuCloud}!</Text>
          </View>
        )}

        {/* lista care apare atunci cand se gestioneaza grupurile de dispozitive */}
        {vizibilListaGrupuri && (
          <View style={[styles.containerListaDispozitive, { height: height / 1.3 }]}>
            <FlatList
              style={{ padding: 15 }}
              showsHorizontalScrollIndicator={false}
              data={dateCont.grupuri_dispozitive}
              renderItem={randerListaToateGrupurile}
              keyExtractor={(item) => item.id.toString()}
            />
          </View>
        )}
      </View>

      {/* MODAL CONFIRMARE STERGERE DISPOZITIV */}
      <DialogAlerta
        vizibil={modalStergereDispozitivVizibil}
        setVizibil={setModalStergereDispozitivVizibil}
        titlu={'Ștergere dispozitiv ' + numeDispozitiv}
        text={'Sunteți sigur că doriți să ștergeți dispozitivul?'}
        buton={() => {
          // se trimit datele catre server
          dispatch({
            type: 'POST_STERGERE_DISPOZITIV_REQUEST',
            payload: {
              token: auth.token,
              id_cloud_dispozitiv: idDispozitiv.trim(),
            },
          });
        }}
      />

      {/* MODAL CONFIRMARE STERGERE GRUP */}
      <DialogAlerta
        vizibil={modalVizibilStergeGrup}
        setVizibil={setModalVizibilStergereGrup}
        titlu={'Ștergere grup ' + numeGrupSters}
        text={'Sunteți sigur că doriți să ștergeți grupul de dispozitive?'}
        buton={() => {
          // se trimit datele catre server
          dispatch({
            type: 'POST_STERGERE_GRUP_REQUEST',
            payload: {
              token: auth.token,
              denumire_grup: numeGrupSters,
            },
          });
        }}
      />

      <IndicatorStare vizibil={indicator} />
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  stilText: {
    alignSelf: 'center',
    fontSize: 18,
    color: theme.text100,
    marginTop: 10,
    fontWeight: 'bold',
  },

  container: {
    width: '97%',
    height: '85%',
    alignSelf: 'center',
  },

  containerListaGrupuri: {
    width: '90%',
    height: 60,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },

  containerListaDispozitive: {
    width: '90%',
    alignSelf: 'center',
    height: '80%',
  },

  cardDispozitiv: {
    width: '100%',
    height: 50,
    marginBottom: 10,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 4 }, // Deplasarea umbrei
    shadowOpacity: 0.9, // Opacitatea umbrei
    shadowRadius: 10, // Raza de blurare a umbrei
    elevation: 5, // Pentru Android
  },

  containerButoane: {
    width: '90%',
    alignSelf: 'center',
    position: 'absolute',
    bottom: 20,
  },
});
