const initialState = {
  loading: false,
  error: null,
  grupuri_utilizate: null,
  dispozitive: null,
  message: null,
};

const GetDispozitiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DISPOZITIVE_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'GET_DISPOZITIVE_SUCCESS':
      return {
        ...state,
        loading: false,
        grupuri_utilizate: action.payload.response.grupuri_utilizate,
        dispozitive: action.payload.response.dispozitive,
        message: action.payload.message,
      };
    case 'GET_DISPOZITIVE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default GetDispozitiveReducer;
