import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';

// Worker saga
function* editareContUtilizator(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'editare_cont_utilizator');
    if (response.error) {
      yield put({ type: 'POST_CONT_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'POST_CONT_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });
      // la schimbarea datelor se preiua din nou datele contului din baza de date
      yield put({ type: 'DATE_CONT_REQUEST', payload: { token: action.payload.token } });
    }
  } catch (error) {
    yield put({ type: 'POST_CONT_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchEditareContUtilizator() {
  yield takeLatest('POST_CONT_REQUEST', editareContUtilizator);
}

export default watchEditareContUtilizator;
