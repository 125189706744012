import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';

// Worker saga
function* reordonare(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'ordonare_dispozitive');
    if (response.error) {
      yield put({ type: 'REORDONARE_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'REORDONARE_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });

      // la adaugarea credentialelor se preiua din nou datele contului din baza de date
      yield put({ type: 'DATE_CONT_REQUEST', payload: { token: action.payload.token } });
    }
  } catch (error) {
    yield put({ type: 'REORDONARE_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchReordonare() {
  yield takeLatest('REORDONARE_REQUEST', reordonare);
}

export default watchReordonare;
