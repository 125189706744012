const initialState = {
  mesajNotificare: null,
  tipNotificare: null,
};

const notificareReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NOTIFICARE_SUCCES':
      return {
        ...state,
        mesajNotificare: action.payload,
        tipNotificare: 'success',
      };

    case 'NOTIFICARE_EROARE':
      return {
        ...state,
        mesajNotificare: action.payload,
        tipNotificare: 'error',
      };

    case 'NOTIFICARE_AVERTISMENT':
      return {
        ...state,
        mesajNotificare: action.payload,
        tipNotificare: 'warning',
      };

    case 'NOTIFICARE_INFO':
      return {
        ...state,
        mesajNotificare: action.payload,
        tipNotificare: 'info',
      };
    default:
      return state;
  }
};

export default notificareReducer;
