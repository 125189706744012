import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';

// Worker saga
function* schimbareParola(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'schimbare_parola');
    if (response.error) {
      yield put({ type: 'POST_SCHIMBARE_PAROLA_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'POST_SCHIMBARE_PAROLA_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });
    }
  } catch (error) {
    yield put({ type: 'POST_SCHIMBARE_PAROLA_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchSchimbareParola() {
  yield takeLatest('POST_SCHIMBARE_PAROLA_REQUEST', schimbareParola);
}

export default watchSchimbareParola;
