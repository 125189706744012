import { takeLatest, call, put, delay } from 'redux-saga/effects';
import { fetchApi } from './fetchConfig';
import { navigateTo } from '../context/NavigationContext';

// Worker saga
function* stergereCont(action) {
  try {
    const response = yield call(fetchApi, action.payload, 'stergere_cont');
    if (response.error) {
      yield put({ type: 'STERGERE_CONT_FAILURE', payload: response.message });
      yield put({ type: 'NOTIFICARE_EROARE', payload: response.message });
    } else {
      yield put({ type: 'STERGERE_CONT_SUCCESS', payload: response.response });
      yield put({ type: 'NOTIFICARE_SUCCES', payload: response.message });

      yield delay(1000);
      // Șterge datele din localStorage
      localStorage.removeItem('userData');
      // Add a delay of 1 second
      navigateTo('/');
      window.location.reload();
    }
  } catch (error) {
    yield put({ type: 'STERGERE_CONT_FAILURE', payload: 'Eroare necunoscuta!' });
  }
}

// Watcher saga
function* watchStergereCont() {
  yield takeLatest('STERGERE_CONT_REQUEST', stergereCont);
}

export default watchStergereCont;
