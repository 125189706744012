import {
  CategoryRounded,
  DevicesOtherRounded,
  EmailRounded,
  Key,
  KeyOff,
  PasswordRounded,
  PersonRounded,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { Autocomplete, TextField } from '@mui/material';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { useEffect, useState } from 'react';
import { theme } from '../constants/themeColors1';
import { styled } from '@mui/material/styles'; // Importă styled

// Stilizare personalizată pentru lista de opțiuni
const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-root': { color: theme.text100 }, // Culoarea textului introdus
  '& .MuiInputLabel-root': { color: theme.text100 }, // Culoarea placeholderului
  '& .MuiAutocomplete-popupIndicator': { color: theme.primary100 }, // Culoarea indicatorului (săgeata pentru deschidere)
  '& .MuiAutocomplete-clearIndicator': { color: theme.accent100 }, // Culoarea butonului `X`
});

// COMPONENTA REUTILIZABILA PENTRU INTRODUCEREA ADRESEI DE EMAIL
export function CasetaTextEmail({ label, value, setValue, error = false, fieldStyle = null }) {
  return (
    <View style={[styles.inputView, fieldStyle]}>
      <EmailRounded sx={[styles.iconView, { color: error && 'red' }]} />
      <TextField
        error={error}
        label={label}
        variant="standard"
        type="input"
        style={styles.inputText}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{ style: { color: theme.text100 } }}
        InputLabelProps={{ style: { color: theme.text100 } }}
      />
    </View>
  );
}

// COMPONENTA REUTILIZABILA PENTRU INTRODUCEREA NUMELUI DE UTILIZATOR
export function CasetaTextNumeUtilizator({ label, value, setValue, error = false, fieldStyle = null }) {
  return (
    <View style={[styles.inputView, fieldStyle]}>
      <PersonRounded sx={[styles.iconView, { color: error && 'red' }]} />
      <TextField
        error={error}
        label={label}
        variant="standard"
        type="input"
        style={styles.inputText}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{ style: { color: theme.text100 } }}
        InputLabelProps={{ style: { color: theme.text100 } }}
      />
    </View>
  );
}

// COMPONENTA REUTILIZABILA PENTRU INTRODUCEREA PAROLEI
export function CasetaTextParola({ label, value, setValue, error = false, fieldStyle = null, showPassword, setShowPassword }) {
  return (
    <View style={[styles.inputView, fieldStyle]}>
      <PasswordRounded sx={[styles.iconView, { color: error && 'red' }]} />
      <TextField
        error={error}
        label={label}
        variant="standard"
        type={showPassword ? 'input' : 'password'}
        style={styles.inputText}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{ style: { color: theme.text100 } }}
        InputLabelProps={{ style: { color: theme.text100 } }}
      />
      {/* BUTON ASCUNDERE/AFISARE PAROLA EWELINK */}
      <TouchableOpacity style={styles.butonIconEye} onPress={() => setShowPassword(!showPassword)}>
        {showPassword ? <Visibility sx={styles.iconEyeOn} /> : <VisibilityOff sx={styles.iconEyeOff} />}
      </TouchableOpacity>
    </View>
  );
}

// COMPONENTA REUTILIZABILA PENTRU INTRODUCERE ACCESS KEY
export function CasetaTextAcessKey({ label, value, setValue, error = false, fieldStyle = null }) {
  return (
    <View style={[styles.inputView, fieldStyle]}>
      <Key sx={[styles.iconView, { color: error && 'red' }]} />
      <TextField
        label={label}
        error={error}
        variant="standard"
        type="input"
        style={styles.inputText}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{ style: { color: theme.text100 } }}
        InputLabelProps={{ style: { color: theme.text100 } }}
      />
    </View>
  );
}

// COMPONENTA REUTILIZABILA PENTRU INTRODUCERE SECRET KEY
export function CasetaTextSecretKey({ label, value, setValue, error = false, fieldStyle = null, showSecretKey, setShowSecretKey }) {
  return (
    <View style={[styles.inputView, fieldStyle]}>
      <KeyOff sx={[styles.iconView, { color: error && 'red' }]} />
      <TextField
        error={error}
        label={label}
        variant="standard"
        type={showSecretKey ? 'input' : 'password'}
        style={styles.inputText}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{ style: { color: theme.text100 } }}
        InputLabelProps={{ style: { color: theme.text100 } }}
      />

      {/* BUTON ASCUNDERE/AFISARE SECRET KEY */}
      <TouchableOpacity style={styles.butonIconEye} onPress={() => setShowSecretKey(!showSecretKey)}>
        {showSecretKey ? <Visibility sx={styles.iconEyeOn} /> : <VisibilityOff sx={styles.iconEyeOff} />}
      </TouchableOpacity>
    </View>
  );
}

// BUTON COMPONENTA REUTILIZABILA
export function ButonSubmit({ label, onPress, fieldStyle, disabled = false }) {
  return (
    <TouchableOpacity
      style={[styles.butonSubmit, fieldStyle, { backgroundColor: disabled ? theme.bg200 : theme.accent100 }]}
      onPress={onPress}
      disabled={disabled}
    >
      <Text style={[styles.textButonSubmit, { color: disabled ? theme.bg100 : theme.text100 }]}>{label}</Text>
    </TouchableOpacity>
  );
}

// BUTON COMPONENTA REUTILIZABILA MAI MIC
export function ButonSubmitMic({ label, onPress, disabled = false }) {
  return (
    <TouchableOpacity
      style={[styles.butonSubmit, { width: '49%', backgroundColor: disabled ? theme.bg200 : theme.accent100 }]}
      onPress={onPress}
      disabled={disabled}
    >
      <Text style={[styles.textButonSubmit, { width: '80%', color: disabled ? theme.bg100 : theme.text100 }]}>{label}</Text>
    </TouchableOpacity>
  );
}

// BUTON BARA LATERALA
export function ButonBaraLaterata({ label, icon, fieldStyle = null, onPress }) {
  return (
    <TouchableOpacity
      style={[{ width: '60px', height: '60px', marginTop: 20, alignItems: 'center', justifyContent: 'center' }, fieldStyle]}
      onPress={onPress}
    >
      {icon}
      <Text style={{ color: theme.text100, fontSize: 11, marginTop: 5, textAlign: 'center' }} adjustsFontSizeToFit={true}>
        {label}
      </Text>
    </TouchableOpacity>
  );
}

// COMPONENTA REUTILIZABILA TITLU
export function TitluFormular({ label }) {
  return (
    <View style={{ width: '90%', height: 40, marginBottom: 20, marginTop: 20, alignSelf: 'center' }}>
      <Text style={{ color: theme.accent200, marginRight: 30, fontSize: 20, marginTop: 3, textAlign: 'right', fontStyle: 'italic' }}>{label}</Text>
      <View style={{ borderColor: theme.accent200, borderWidth: 1, width: '97%', alignSelf: 'center', marginTop: 10 }} />
    </View>
  );
}

// COMPONENTA REUTILIZABILA PENTRU INTRODUCEREA DENUMIRII UNUI NOU DISPOZITIV
export function CasetaTextNumeDispozitiv({ label, value, setValue, error = false, fieldStyle = null }) {
  return (
    <View style={[styles.inputView, { marginBottom: '30px', width: '90%' }, fieldStyle]}>
      <DevicesOtherRounded sx={[styles.iconView, { color: error && 'red' }]} />
      <TextField
        error={error}
        label={label}
        variant="standard"
        type="input"
        style={styles.inputText}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{ style: { color: theme.text100 } }}
        InputLabelProps={{ style: { color: theme.text100 } }}
      />
    </View>
  );
}

// COMPONENTA REUTILIZATIBILA PENTRU SELECTAREA GRUPULUI DIN CARE VA FACE PARTE DISPOZITIVUL
export function CasetaTextNumeGrup({ label, value, setValue, error = false, fieldStyle = null, disabled = false, grupuriDispozitive }) {
  // Starea internă pentru a gestiona valoarea introdusă de utilizator
  const [inputValue, setInputValue] = useState(value || '');

  // Sincronizează `inputValue` cu `value` atunci când `value` se schimbă
  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  return (
    <View style={[styles.inputView, { marginBottom: '30px', width: '90%' }, fieldStyle]}>
      {/* Iconiță care poate schimba culoarea în funcție de eroare */}
      <CategoryRounded sx={[styles.iconView, { color: error && 'red' }]} />

      <StyledAutocomplete
        sx={{ width: '85%' }}
        freeSolo
        options={grupuriDispozitive.map((option) => option.nume)} // Opțiunile disponibile pentru autocompletare
        value={value || ''} // Ne asigurăm că valoarea nu este null
        onChange={(event, newValue) => {
          setValue(newValue || ''); // Setăm valoarea la string gol dacă este null
          setInputValue(newValue || ''); // Actualizăm și valoarea afișată în input
        }}
        inputValue={inputValue || ''} // Ne asigurăm că valoarea inputului nu este null
        onInputChange={(event, newInputValue) => {
          if (!disabled) {
            // Permite modificarea doar dacă input-ul nu este dezactivat
            setInputValue(newInputValue || ''); // Actualizează valoarea input-ului intern, sau o setează la string gol
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error} // Indică dacă există o eroare în input
            label={label} // Eticheta câmpului de input
            value={inputValue} // Afișează valoarea sincronizată cu `value`
            variant="standard"
            style={{ width: '89%%', alignSelf: 'center', color: theme.text100 }}
            // disabled={disabled} // Dezactivează input-ul dacă `disabled` este true
            onKeyDown={(e) => {
              // Blochează introducerea de la tastatură când `disabled` este true
              if (disabled) {
                e.preventDefault(); // Previne introducerea de text de la tastatură
              }
            }}
          />
        )}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: theme.bg200, // Fundalul listei
              color: theme.text100, // Culoarea textului în listă
            },
          },
          option: {
            sx: {
              '&[aria-selected="true"]': {
                backgroundColor: theme.primary200, // Fundalul opțiunii selectate
                color: theme.bg100, // Culoarea textului opțiunii selectate
              },
              '&:hover': {
                backgroundColor: theme.primary100, // Fundalul opțiunii la hover
              },
            },
          },
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  iconView: {
    color: theme.accent100,
    width: '10%',
    height: '50%',
    alignSelf: 'center',
  },

  inputView: {
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: theme.bg200,
    width: '80%',
    marginBottom: '.7%',
    height: '60px',
    alignSelf: 'center',
    shadowColor: '#000', // Culoarea umbrei
    shadowOffset: { width: 0, height: 2 }, // Deplasarea umbrei
    shadowOpacity: 0.5, // Opacitatea umbrei
    shadowRadius: 10, // Raza de blurare a umbrei
    elevation: 5, // Pentru Android
  },

  inputText: {
    width: '85%',
    alignSelf: 'center',
  },

  iconEyeOff: {
    color: theme.accent100,
    width: '100%',
    height: '50%',
  },

  iconEyeOn: {
    color: theme.primary200,
    width: '100%',
    height: '50%',
  },

  butonIconEye: {
    width: '10%',
    height: '100%',
    justifyContent: 'center',
  },

  butonSubmit: {
    borderRadius: 10,
    marginBottom: 10,
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    height: '50px',
    alignSelf: 'center',
    shadowColor: '#000', // Culoarea umbrei
    shadowOffset: { width: 0, height: 2 }, // Deplasarea umbrei
    shadowOpacity: 0.7, // Opacitatea umbrei
    shadowRadius: 10, // Raza de blurare a umbrei
    elevation: 5, // Pentru Android
  },

  textButonSubmit: {
    fontWeight: 'bold',
    fontSize: 16,
    width: '90%',
    textAlign: 'center',
  },

  iconButonSubmit: {
    width: '35px',
    height: '35px',
  },
});
