import { combineReducers } from 'redux';
import authReducer from './AuthReducer';
import notificareReducer from './NotificareReducer';
import CreareContReducer from './CreareContReducer';
import DeconectareReducer from './DeconectareReducer';
import GetDateContReducer from './GetDateContReducer';
import contUtilizatorReducer from './PostContUtilizatorReducer';
import schimbareParolaReducer from './PostSchimbareParola';
import stergereContReducer from './StergereContReducer';
import stergereCredentialeReducer from './StergeCredentialeReducer';
import postCredentialeReducer from './PostCredentialeReducer';
import postAddDispozitivReducer from './PostAddDispozitivReducer';
import GetDispozitiveReducer from './GetDispozitiveReducer';
import postEditareDispozitivReducer from './PostEditareDispozitivReducer';
import postStergereDispozitivReducer from './PostStergereDispozitivReducer';
import postStergereGrupReducer from './PostStergereGrupDispozitiveReducer';
import reordonareReducer from './ReordonareReducer';
import postComandaReducer from './PostComandaDispozitivReducer';
// alte reducer-e

const rootReducer = combineReducers({
  auth: authReducer,
  notificare: notificareReducer,
  creareCont: CreareContReducer,
  deconectare: DeconectareReducer,
  dateCont: GetDateContReducer,
  postDateCont: contUtilizatorReducer,
  postSchimbareParola: schimbareParolaReducer,
  stergereCont: stergereContReducer,
  stergereCredentiale: stergereCredentialeReducer,
  postCredentiale: postCredentialeReducer,
  adaugareDispozitiv: postAddDispozitivReducer,
  dateDispozitive: GetDispozitiveReducer,
  editareDispozitiv: postEditareDispozitivReducer,
  stergereDispozitiv: postStergereDispozitivReducer,
  stergereGrup: postStergereGrupReducer,
  reordonare: reordonareReducer,
  comandaDispozitiv: postComandaReducer,
  // alte reducer-e
});

export default rootReducer;
